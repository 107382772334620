import React from "react";
import { useForm, FieldValues, Control } from "react-hook-form";
import { Value } from "react-hook-schema-form";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Field, Schema } from "schemaComponents";
import Button from "react-bootstrap/Button";
import { Alert } from "react-bootstrap";
import { useConfirm } from "hooks/dialogs";

import "./SchemaForm.scss";

export type SchemaFormParameter = {
  title?: string;
  subtitle?: string;
  confirmMessage?: string | false; // deprecated
  schema: Schema;
  enableFormAlert?: boolean;
  preview?: {
    title: string;
    component: React.ComponentType<{ control: Control }>;
  };
  edit?: {
    title?: string;
    confirm: string | false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler?: (values: any) => Promise<void>;
    path?: string;
  };
  back?: {
    title?: string;
    confirm?: string | false;
    handler?: () => Promise<void>;
    path?: string;
  };
  remove?: {
    title?: string;
    confirm?: string | false;
    handler?: () => Promise<void>;
    path?: string;
  };
  backPath?: string;
  hook?: (control: Control) => void;
};

const SchemaForm = ({
  data,
  readOnly,
  parameter,
}: {
  data: Value;
  parameter: SchemaFormParameter;
  readOnly?: boolean;
}) => {
  const {
    back,
    edit,
    remove,
    schema,
    preview,
    enableFormAlert,
    backPath,
    hook,
  } = parameter;
  const { handleSubmit, control, formState } = useForm({
    defaultValues: data,
  });
  hook?.(control);
  const navigate = useNavigate();
  const hasErrors = enableFormAlert && Object.keys(formState.errors).length > 0;
  const PreviewComponent = preview?.component;
  const confirm = useConfirm();
  const clickSubmit = async (value: FieldValues) => {
    if (edit?.confirm !== false) {
      const result = await confirm({
        message: edit?.confirm || "編集しますか？",
      });
      if (!result) return;
    }
    try {
      await edit?.handler?.(value);
      const path = edit?.path || backPath;
      path && navigate(path);
    } catch (e) {
      console.error(e);
    }
  };
  const clickDelete = async () => {
    if (remove?.confirm !== false) {
      const result = await confirm({
        message: remove?.confirm || "削除しますか？",
      });
      if (!result) return;
    }
    try {
      await remove?.handler?.();
      const path = remove?.path || backPath;
      path && navigate(path);
    } catch (e) {
      console.error(e);
    }
  };
  const clickBack = async () => {
    if (back?.confirm) {
      const result = await confirm({
        message: back?.confirm,
      });
      if (!result) return;
    }
    try {
      await back?.handler?.();
      const path = back?.path || backPath;
      path && navigate(path);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <div className="tile">
        <div className="card tile">
          <div className="card-content">
            <form
              onSubmit={handleSubmit(clickSubmit)}
              className="adjust-width-expand"
              noValidate
            >
              <Field control={control} schema={schema} readOnly={readOnly} />
              {!readOnly && (
                <>
                  {(edit || remove || back || backPath) && <hr />}
                  {edit && hasErrors && (
                    <Alert variant="danger">
                      未入力の項目があります。上にスクロールして該当箇所を修正してください
                    </Alert>
                  )}
                  <div>
                    {edit && (
                      <Button type="submit" className="me-2">
                        {edit?.title || "編集"}
                      </Button>
                    )}
                    {remove && (
                      <Button
                        onClick={clickDelete}
                        type="button"
                        variant="danger"
                        className="me-2"
                      >
                        {remove.title || "削除"}
                      </Button>
                    )}
                    {(back || backPath) && (
                      <Button
                        onClick={clickBack}
                        type="button"
                        variant="outline-secondary"
                        className="me-2"
                      >
                        {back?.title || "もどる"}
                      </Button>
                    )}
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
        {PreviewComponent && (
          <div className="card tile">
            <header className="card-header">
              <p className="card-header-title">{preview?.title}</p>
            </header>
            <div className="card-content" style={{ padding: "0.5rem" }}>
              <PreviewComponent control={control}></PreviewComponent>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const SchemaFormPage = ({
  data,
  parameter,
  loading,
  readOnly,
}: {
  data: Value;
  parameter: SchemaFormParameter;
  loading?: boolean;
  readOnly?: boolean;
}) => {
  const { title, subtitle } = parameter;
  return (
    <>
      {title && (
        <section className="hero">
          <div className="hero-body">
            <h1 className="title">{title}</h1>
            <h2 className="subtitle">{subtitle}</h2>
          </div>
        </section>
      )}
      <section>
        {loading ? (
          <div style={{ padding: "20px" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <SchemaForm data={data} parameter={parameter} readOnly={readOnly} />
        )}
      </section>
    </>
  );
};

export default SchemaFormPage;
