import React, { useMemo } from "react";
import { useApiLogDoc, useApiLogPage } from "models/apiLog";
import { APILOG, APILOGS } from "../route";
import { mediaItem } from "models/mediaItem";
import { Button } from "react-bootstrap";

export const UPDATE_APILOG = APILOG.sub("", ApiLog);

function ApiLog({
  apiLogId,
  campaignId,
  hashTagId,
}: {
  apiLogId: string;
  campaignId: string;
  hashTagId: string;
}) {
  const { exists: logExists, ref } = useApiLogDoc({
    hashTagId,
    campaignId,
    apiLogId,
  });
  const { list } = useApiLogPage({
    hashTagId,
    campaignId,
    apiLogId,
  });
  console.log({ list });

  const { items, likeCount, commentCount, postCount } = useMemo(() => {
    const arrayOfArray = list?.map((item) => item.data);
    const items = arrayOfArray
      ? ([] as mediaItem[]).concat(...arrayOfArray)
      : undefined;

    let likeCount = 0;
    let commentCount = 0;
    for (const item of items || []) {
      likeCount += item.like_count || 0;
      commentCount += item.comments_count || 0;
    }
    const postCount = items?.length;
    return { items, likeCount, commentCount, postCount };
  }, [list]);

  return (
    <>
      <section className="hero">
        <div className="hero-body">
          <h1 className="title">ログ表示</h1>
        </div>
      </section>
      {!logExists && (
        <section className="main-section">
          <Button onClick={() => ref.set({})}>ただちに取得</Button>
        </section>
      )}
      {logExists && items && (
        <section className="main-section">
          <div className="row">
            <div className="col-sm-3">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-file-document-outline" />
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Number of Customers"
                  >
                    投稿
                  </h5>
                  <h3 className="mt-3 mb-3">{postCount}</h3>
                  {/* <p className="mb-0 text-muted">
                    <span className="text-success me-2">
                      <i className="mdi mdi-arrow-up-bold"></i>
                      {postCount / 10}
                    </span>
                    <span className="text-nowrap">前日比</span>
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-thumb-up-outline" />
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Number of Customers"
                  >
                    いいね
                  </h5>
                  <h3 className="mt-3 mb-3">{likeCount}</h3>
                  {/* <p className="mb-0 text-muted">
                    <span className="text-danger me-2">
                      <i className="mdi mdi-arrow-down-bold"></i> 10
                    </span>
                    <span className="text-nowrap">前日比</span>
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-comment-outline" />
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Number of Customers"
                  >
                    コメント
                  </h5>
                  <h3 className="mt-3 mb-3">{commentCount}</h3>
                  {/* <p className="mb-0 text-muted">
                    <span className="text-success me-2">
                      <i className="mdi mdi-arrow-up-bold"></i> 25
                    </span>
                    <span className="text-nowrap">前日比</span>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="main-section">
        <div
          style={{
            display: "flex",
            flexFlow: "wrap",
          }}
        >
          {items?.map((item) => {
            return (
              <div
                key={item.id}
                style={{ height: "180px", width: "150px" }}
                className="instagram-list-item"
              >
                <a target={item.id} href={item.permalink}>
                  {item.media_type === "VIDEO" ? (
                    <video
                      src={item.media_url}
                      style={{
                        height: "150px",
                        width: "150px",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <img
                      src={item.media_url}
                      style={{
                        height: "150px",
                        width: "150px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </a>
                <div>
                  <i className="mdi mdi-thumb-up-outline" />
                  {item.like_count}&nbsp;
                  <i className="mdi mdi-comment-outline" />
                  {item.comments_count}
                </div>
              </div>
            );
          })}
        </div>
        <div></div>
      </section>
    </>
  );
}

export default ApiLog;
