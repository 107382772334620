import {
  getFirestore,
  collection,
  doc,
  orderBy,
  query,
  CollectionReference,
  updateDoc,
  FieldValue,
  deleteField,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { customTagModel } from "./data";
import { createDocumentHook, createQueryHook } from "./hook";
const firestore = getFirestore(firebaseApp);

export const customTagCollection = ({ campaignId }: { campaignId: string }) => {
  return collection(
    firestore,
    `/campaign/${campaignId}/customTag`
  ) as CollectionReference<customTagModel>;
};

export const useCustomTagDoc = createDocumentHook(
  ({
    campaignId,
    customTagId,
  }: {
    campaignId: string;
    customTagId?: string;
  }) => {
    const collection = customTagCollection({ campaignId });
    return [
      customTagId
        ? doc(collection, customTagId)
        : (data: customTagModel) => doc(collection),
    ];
  },
  "customTagId"
);

export const useCustomTagList = createQueryHook(
  ({ campaignId }: { campaignId: string }) => {
    const collection = customTagCollection({ campaignId });
    return [query(collection, orderBy("createdAt", "desc")), collection];
  },
  "id",
  "createdAt"
);
