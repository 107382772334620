import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "ハッシュタグID",
      propertyName: "hashTagId",
      schema: { schemaType: "text" },
    },
    {
      title: "ハッシュタグ名",
      propertyName: "hashTagName",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
  ],
};
export default schema;
