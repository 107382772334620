import React from "react";
import { Schema } from "schemaComponents";

export default ({ isEditing }: { isEditing: boolean }): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "ユーザID",
        propertyName: "userId",
        schema: {
          schemaType: "externalKey",
          titleKey: "name",
          resourcePath: `/adminUser`,
          readOnly: true,
        },
      },
      {
        title: "1時間あたりの最大アクセス数",
        propertyName: "limit",
        schema: {
          schemaType: "number",
          required: true,
        },
      },
    ],
  };
};
