import React from "react";

import schema from "./schema";
import SchemaListPage from "components/SchemaListPage";
import { useCampaignList } from "models/campaign";
import { CAMPAIGNS } from "../route";
import { resolveRoute } from "pages";
import { useAdminAuthentication } from "hooks/auth";

export const LIST_CAMPAIGNS = CAMPAIGNS.sub("", Campaigns);

function Campaigns() {
  const { user } = useAdminAuthentication();
  const { list, loading, error } = useCampaignList(
    user?.role !== "master" ? user?.campaignIds : undefined
  );
  const parameter = {
    title: "キャンペーン一覧",
    edit: {
      path: (campaignId: string) => resolveRoute("LIST_MEDIAS", { campaignId }),
    },
    create: { path: () => resolveRoute("ADD_CAMPAIGN") },
    schema,
  };
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
    ></SchemaListPage>
  );
}

export default Campaigns;
