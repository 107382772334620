import React from "react";
import classNames from "classnames";
import {
  useTable,
  useSortBy,
  usePagination,
  Column,
  UseSortByColumnProps,
  UsePaginationState,
  TableInstance,
  HeaderGroup,
  UsePaginationInstanceProps,
  UseSortByOptions,
  TableState,
} from "react-table";
import Pagination from "react-bootstrap/Pagination";

import { useFormatter } from "hooks/intl";
import classes from "./Table.module.scss";
import "./TableMobile.scss";
import Form from "react-bootstrap/Form";
import { Stack } from "react-bootstrap";

type DefaultTableContentType = Column extends Column<infer U> ? U : never;

export type ExtendedColumn<D extends DefaultTableContentType> = Column<D> &
  UseSortByOptions<D>;

const Table = <D extends DefaultTableContentType>({
  columns,
  data,
}: {
  columns: ExtendedColumn<D>[];
  data: D[];
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable<D>(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
  ) as TableInstance<D> &
    UsePaginationInstanceProps<D> & {
      state: TableState<D> & UsePaginationState<D>;
    };

  const { formatMessage } = useFormatter();
  const perPage = formatMessage("Table.perPage");

  return (
    <div className="table-wrapper">
      <div className="table-main">
        <table className="table is-striped is-hoverable" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                {(
                  headerGroup.headers as (HeaderGroup<D> &
                    UseSortByColumnProps<D>)[]
                ).map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    className={classNames(
                      { [classes.isCurrentSort]: column.isSorted },
                      { [classes.isSortable]: column.canSort }
                    )}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    <div>
                      {column.render("Header")}
                      {column.isSorted && (
                        <span className="icon">
                          <i
                            className={classNames(
                              "mdi",
                              classes.tableIcon,
                              { "mdi-arrow-down": column.isSortedDesc },
                              { "mdi-arrow-up": !column.isSortedDesc }
                            )}
                          />
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        data-label={cell.column.Header}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Stack direction="horizontal" style={{ padding: "20px" }}>
        <div>
          <Form.Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 50, 100].map((size) => (
              <option key={size} value={size}>
                {size} {perPage}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="ms-auto">
          <Pagination>
            <Pagination.First
              disabled={pageIndex === 0}
              onClick={() => gotoPage(0)}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />

            {pageIndex !== 0 && (
              <Pagination.Item onClick={() => gotoPage(0)}>{1}</Pagination.Item>
            )}
            {pageIndex > 3 && <Pagination.Ellipsis disabled />}
            {pageIndex === 3 && (
              <Pagination.Item onClick={() => gotoPage(1)}>{2}</Pagination.Item>
            )}
            {pageIndex - 1 > 0 && (
              <Pagination.Item onClick={() => previousPage()}>
                {pageIndex}
              </Pagination.Item>
            )}
            <Pagination.Item onClick={() => previousPage()} active>
              {pageIndex + 1}
            </Pagination.Item>
            {canNextPage && (
              <Pagination.Item onClick={() => nextPage()}>
                {pageIndex + 2}
              </Pagination.Item>
            )}
            {pageCount - pageIndex === 4 && (
              <Pagination.Item onClick={() => gotoPage(pageCount - 2)}>
                {pageCount - 1}
              </Pagination.Item>
            )}
            {pageCount - pageIndex > 4 && <Pagination.Ellipsis disabled />}
            {pageIndex + 2 < pageCount && (
              <Pagination.Item onClick={() => gotoPage(pageCount - 1)}>
                {pageCount}
              </Pagination.Item>
            )}
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              disabled={pageIndex + 2 >= pageCount}
              onClick={() => gotoPage(pageCount - 1)}
            />
          </Pagination>
        </div>
      </Stack>
    </div>
  );
};

export default Table;
