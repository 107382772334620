import React from "react";
import { ExtendedColumn } from "components/Table";
import { DocumentData } from "firebase/firestore";

import { resolve, SchemaTypes, PropertyType } from "schemaComponents";

const propertyToColumn = ({
  propertyName,
  propertyId,
  schema,
  title,
}: PropertyType): ExtendedColumn<DocumentData> | undefined => {
  const { display: Display } = resolve(schema.schemaType as SchemaTypes);
  if (Display) {
    return {
      accessor: propertyName || propertyId || "",
      Header: title,
      Cell: ({ row }) => {
        return (
          <Display
            value={propertyName ? row.original[propertyName] : row.original}
            schema={schema}
          />
        );
      },
    };
  } else if (schema.schemaType !== "object") {
    return {
      Header: title,
      accessor: propertyName || propertyId || "",
    };
  }
};

export const propertiesToColumns = (
  properties: PropertyType[]
): ExtendedColumn<DocumentData>[] =>
  properties
    .map(propertyToColumn)
    .filter((v) => v) as ExtendedColumn<DocumentData>[];
