import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";

import "./selector.scss";
import { DisplayComponent } from "./common/displayWrapper";

interface SelectorSchema extends BaseSchema {
  schemaType: "selector";
  uiType?: "vertial" | "horizontal";
  options: {
    title: string;
    value: string | number | null;
  }[];
}

const SelectorSchemaComponent: SchemaComponent<SelectorSchema> = (
  props: SchemaComponentInternalProps<SelectorSchema>
) => {
  const { readOnly, schema } = props;
  const { options, uiType } = schema;
  const {
    registerProps: { onChange },
    value,
    fieldState,
  } = useField(props, [requiredValidator]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <div
        style={{ margin: "5px 0" }}
        className={classNames(
          {
            "has-error": fieldState.invalid,
          },
          uiType && `selector-${uiType}`
        )}
      >
        {options.map(({ title, value: optionValue }, index) => (
          <span
            key={index}
            className={classNames(
              "selectorOption",
              optionValue === value && "active"
            )}
            onClick={(e) => {
              onChange(optionValue !== value ? optionValue : null);
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {title}
          </span>
        ))}
      </div>
    </FieldWrapper>
  );
};

SelectorSchemaComponent.display = DisplayComponent(
  "SelectorDisplayComponent",
  (value, schema) => {
    const { options } = schema;
    return options.find((option) => option.value === value)?.title || "未設定";
  }
);

export default SelectorSchemaComponent;
