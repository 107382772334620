import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { StyledFirebaseAuth } from "react-firebaseui";
import classes from "./Login.module.scss";
import { useAdminAuthentication } from "hooks/auth";
import Alert from "react-bootstrap/Alert";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  EmailAuthProvider,
} from "firebase/auth";
import firebaseApp from "firebaseApp";
import ErrorMessage from "components/ErrorMessage";
import { ADMIN as BASE } from "..";
import { resolveRoute } from "pages";

const auth = getAuth(firebaseApp);

export const LOGIN = BASE.sub("/login", Login);

function Login() {
  const {
    user,
    isAdmin,
    isNotAdmin,
    error: authError,
  } = useAdminAuthentication();

  console.log("user", user);

  const [signInError, setSignInError] = useState(
    null as { message: string } | null
  );
  const error = authError || signInError;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSignInSuccessHandler = (authResult: any) => {
    console.log("authResult", authResult);
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSignInFailHandler = (signInEror: any) => {
    console.log("signInEror", signInEror);
    setSignInError({ message: signInEror });
  };

  if (isAdmin) {
    return <Navigate to={resolveRoute("ADMIN_HOME")} replace />;
  }

  return (
    <>
      <div className="consumer-container wide-form">
        <section className="hero">
          <div className="hero-body">
            <h1 className="title">ログイン</h1>
          </div>
        </section>
        <section className="main-section">
          <div className={classes.socialButtons}>
            <StyledFirebaseAuth
              uiConfig={uiConfig(onSignInSuccessHandler, onSignInFailHandler)}
              uiCallback={(ui) => console.log(ui)}
              firebaseAuth={auth}
            />
          </div>
          <Link to={resolveRoute("REGISTER")}>パスワードが分からない方</Link>
          {error && (
            <Alert variant="danger">
              <ErrorMessage error={error} />
            </Alert>
          )}
          {isNotAdmin && (
            <Alert variant="danger">
              ログイン中のメールアドレス({user?.email})
              には管理者権限がありません。別のメールアドレスでログインしなおすか、管理者まで連絡してください
            </Alert>
          )}
        </section>
      </div>
    </>
  );
}

export default Login;

const uiConfig = (
  onSignInSuccessHandler:
    | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((authResult: any, redirectUrl?: string) => boolean),
  onSignInFailHandler:
    | undefined
    | ((error: firebaseui.auth.AuthUIError) => Promise<void> | void)
): firebaseui.auth.Config => {
  return {
    callbacks: {
      signInSuccessWithAuthResult: onSignInSuccessHandler,
      signInFailure: onSignInFailHandler,
    },
    signInFlow: "popup",
    signInSuccessUrl: "/home",
    signInOptions: [
      {
        provider: EmailAuthProvider.PROVIDER_ID,
        disableSignUp: { status: false },
      },
      GoogleAuthProvider.PROVIDER_ID,
      {
        provider: FacebookAuthProvider.PROVIDER_ID,
        scopes: ["pages_show_list", "instagram_basic"],
      },
    ],
  };
};
