import React from "react";
import { Schema } from "schemaComponents";
import { couponUrl } from "utils/coupon";

export default ({ isEditing }: { isEditing: boolean }): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "カスタムタグId",
        propertyName: "customTagId",
        schema: {
          schemaType: "text",
          readOnly: true,
          required: true,
        },
      },
      {
        title: "カスタムタグ名",
        propertyName: "customTagName",
        schema: {
          schemaType: "text",
          // readOnly: isEditing,
          required: true,
        },
      },
      {
        title: "オプション",
        propertyName: "options",
        schema: {
          schemaType: "json",
        },
      },
      {
        title: "絞り込みに利用",
        propertyName: "enableFilter",
        schema: {
          schemaType: "boolean",
          uiType: "switch",
        },
      },
      {
        title: "編集可能",
        propertyName: "enableEdit",
        schema: {
          schemaType: "boolean",
          uiType: "switch",
        },
      },
    ],
  };
};
