import { Schema } from "schemaComponents";

const schema: Schema = {
  schemaType: "object",
  properties: [
    {
      title: "サムネイル",
      propertyName: "photoURL",
      schema: { schemaType: "image", readOnly: true },
    },
    {
      title: "uid",
      propertyName: "uid",
      schema: { schemaType: "text", readOnly: true },
    },
    {
      title: "E-MAIL",
      propertyName: "email",
      schema: { schemaType: "text", readOnly: true },
    },
    {
      title: "表示名",
      propertyName: "displayName",
      schema: { schemaType: "text", readOnly: true },
    },
    {
      title: "権限",
      propertyName: "customClaims.role",
      schema: {
        schemaType: "selector",
        readOnly: true,
        options: [
          {
            title: "マスター",
            value: "master",
          },
          {
            title: "クライアント",
            value: "client",
          },
        ],
      },
    },
    {
      title: "キャンペーン",
      propertyName: "customClaims.campaignIds",
      schema: {
        schemaType: "multipleExternalKey",
        resourcePath: "/campaign",
        titleKey: "campaignName",
        readOnly: true,
      },
    },
    {
      title: "トークン更新日時",
      propertyName: "tokensValidAfterTime",
      schema: { schemaType: "datetime", readOnly: true },
    },
    {
      title: "アカウント作成日時",
      propertyName: "metadata.creationTime",
      schema: { schemaType: "datetime", readOnly: true },
    },
    {
      title: "最終利用日時",
      propertyName: "metadata.lastRefreshTime",
      schema: { schemaType: "datetime", readOnly: true },
    },
    {
      title: "最終ログイン日時",
      propertyName: "metadata.lastSignInTime",
      schema: { schemaType: "datetime", readOnly: true },
    },
  ],
};
export default schema;
