import React from "react";
import { Schema } from "schemaComponents";

export default ({ isEditing }: { isEditing: boolean }): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "キャンペーン名",
        propertyName: "campaignName",
        schema: {
          schemaType: "text",
          required: true,
        },
      },
      {
        title: "詳細説明",
        propertyName: "description",
        schema: {
          schemaType: "multilineText",
        },
      },
    ],
  };
};
