import React from "react";
import ConsumerLayout from "components/ConsumerLayout";
import { Outlet } from "react-router-dom";
import { ROOT } from "pages/root";

export const CONSUMER = ROOT.sub("/shop/:shopId", Consumer, {
  parent: true,
});

function Consumer() {
  return <Outlet />;
}
