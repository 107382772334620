/* eslint-disable camelcase */
import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useHashTagDoc } from "models/hashTag";
import { HASHTAG, HASHTAGS } from "../route";
import { resolveRoute } from "pages";
import { hashTagModel } from "models/data";
import { InstagramApi } from "pages/admin/profile/instagramApi";
import { adminOwnerCollection } from "models/adminOwner";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export const UPDATE_HASHTAG = HASHTAG.sub("", HashTag);
export const ADD_HASHTAG = HASHTAGS.sub("/new", HashTag);

function HashTag({
  hashTagId,
  campaignId,
}: {
  hashTagId?: string;
  campaignId: string;
}) {
  const isEditing = !!hashTagId;
  const { data, loading, ref } = useHashTagDoc({ hashTagId, campaignId });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "ハッシュタグ編集" : "ハッシュタグ新規作成",
    edit: !isEditing
      ? {
          title: `${isEditing ? "編集" : "作成"}`,
          confirm: `${isEditing ? "編集" : "作成"}しますか？`,
          handler: async (data: hashTagModel) => {
            const auth = getAuth();

            const profile = (
              await getDoc(doc(adminOwnerCollection, auth.currentUser?.uid))
            ).data();
            const access_token = profile?.facebook?.credential?.accessToken;
            const user_id = profile?.instagramUserId;
            if (!access_token || !user_id) {
              return;
            }
            const instagramApi = new InstagramApi({
              access_token,
              user_id,
            });
            const response = await instagramApi.hashTagSearch({
              hashTagName: data.hashTagName,
            });

            data.hashTagId = response.data[0].id;
            ref.set(data);
          },
        }
      : undefined,
    remove: isEditing ? { handler: ref.delete } : undefined,
    schema: getSchema({ isEditing }),
    backPath: resolveRoute("LIST_HASHTAGS", { campaignId }),
  };
  return (
    <SchemaFormPage
      data={isEditing ? data : {}}
      parameter={parameter}
      loading={isEditing && loading}
    />
  );
}

export default HashTag;
