import {
  getFirestore,
  collection,
  doc,
  orderBy,
  query,
  CollectionReference,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { apiAccountModel } from "./data";
import { createDocumentHook, createQueryHook } from "./hook";
const firestore = getFirestore(firebaseApp);

export const apiAccountCollection = ({
  campaignId,
}: {
  campaignId: string;
}) => {
  return collection(
    firestore,
    `/campaign/${campaignId}/apiAccount`
  ) as CollectionReference<apiAccountModel>;
};

export const useApiAccountDoc = createDocumentHook(
  ({
    campaignId,
    apiAccountId,
  }: {
    campaignId: string;
    apiAccountId?: string;
  }) => {
    const collection = apiAccountCollection({ campaignId });
    return [
      apiAccountId
        ? doc(collection, apiAccountId)
        : (data: apiAccountModel) => doc(collection, data.userId),
    ];
  },
  "userId"
);

export const useApiAccountList = createQueryHook(
  ({ campaignId }: { campaignId: string }) => {
    const collection = apiAccountCollection({ campaignId });
    return [query(collection, orderBy("createdAt", "desc")), collection];
  },
  "id",
  "createdAt"
);
