import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  BaseSchema,
  useValue,
  Value,
} from "react-hook-schema-form";
import { DisplayComponent } from "./common/displayWrapper";

type JSXElementLike = string | number | JSX.Element;

interface MessageSchema extends BaseSchema {
  schemaType: "message";
  text: JSXElementLike | (({ value }: { value: Value }) => JSXElementLike);
}

const applyValue = (
  func: JSXElementLike | (({ value }: { value: Value }) => JSXElementLike),
  value: Value
) => {
  if (typeof func === "function") {
    return func({ value });
  } else {
    return func;
  }
};

const MessageSchemaComponent: SchemaComponent<MessageSchema> = (
  props: SchemaComponentInternalProps<MessageSchema>
) => {
  const { schema } = props;
  const { text } = schema;
  const value = useValue(props);
  return <>{applyValue(text, value)}</>;
};

MessageSchemaComponent.display = DisplayComponent(
  "MessageDisplayComponent",
  (value, schema) => {
    const { text } = schema;
    return applyValue(text, value);
  }
);

export default MessageSchemaComponent;
