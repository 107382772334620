import React, { useEffect } from "react";
import { useModal, ModalComponentProps } from "react-hooks-async-modal";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./modal.scss";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { Value } from "react-hook-schema-form";

const confirm = ({
  onResolve,
  message,
}: ModalComponentProps<boolean> & { message: string | JSX.Element }) => {
  return (
    <Modal show={true} onHide={() => onResolve(false)}>
      <Modal.Header closeButton>
        <Modal.Title>確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onResolve(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => onResolve(true)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const useConfirm = () => {
  return useModal<{ message: string | JSX.Element }, boolean>(confirm);
};

const alert = ({
  onResolve,
  message,
}: ModalComponentProps<void> & { message: string }) => {
  return (
    <Modal show={true} onHide={() => onResolve()}>
      <Modal.Header closeButton>
        <Modal.Title>確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={() => onResolve()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const useAlert = () => {
  return useModal<{ message: string }>(alert);
};

const policy = ({
  onResolve,
  message,
  title,
}: ModalComponentProps<void> & { message: string; title: string }) => {
  return (
    <Modal show={true} className="policy">
      <Modal.Header>
        <Modal.Title>
          {title}
          <p style={{ fontSize: "0.7em" }}>
            スクロールして最後までお読みになってください
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <p style={{ whiteSpace: "pre-wrap" }}>{message}</p>
        <Button variant="primary" onClick={() => onResolve()}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};
export const usePolicy = () => {
  return useModal<{ message: string; title: string }>(policy);
};

const download = ({
  onResolve,
  message,
  url,
}: ModalComponentProps<boolean> & {
  message: string | JSX.Element;
  url: string;
}) => {
  return (
    <Modal show={true} onHide={() => onResolve(false)}>
      <Modal.Header closeButton>
        <Modal.Title>確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onResolve(false)}>
          Cancel
        </Button>
        <a
          className="btn btn-primary"
          href={url}
          onClick={(e) => onResolve(true)}
          download
        >
          OK
        </a>
      </Modal.Footer>
    </Modal>
  );
};

export const useDownload = () => {
  const dialog = useModal<
    { message: string | JSX.Element; url: string },
    boolean
  >(download);
  return async (
    params:
      | {
          blob: Blob;
        }
      | {
          data: BlobPart[];
          mimeType: string;
        }
  ) => {
    const blob = (() => {
      if ("blob" in params) {
        return params.blob;
      } else {
        return new Blob(params.data, { type: params.mimeType });
      }
    })();
    const url = URL.createObjectURL(blob);
    await dialog({ message: "ダウンロード", url });
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 1000);
  };
};

const modalForm = ({
  onResolve,
  params,
}: ModalComponentProps<Value> & { params: SchemaFormParameter }) => {
  return (
    <Modal show={true} className="policy" onHide={() => onResolve(undefined)}>
      <Modal.Header closeButton>
        <Modal.Title>{params.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "auto", height: "70vh" }}>
        <SchemaFormPage
          data={{}}
          parameter={{
            ...params,
            title: undefined,
            edit: {
              ...params.edit,
              confirm: false,
              handler: async (value) => {
                onResolve(value);
              },
            },
          }}
        />
      </Modal.Body>
    </Modal>
  );
};
export const useModalForm = (): (<T = Value>(props: {
  params: SchemaFormParameter;
}) => Promise<T>) => {
  return useModal<{ params: SchemaFormParameter }, Value>(modalForm);
};

const toost = ({
  onResolve,
  message,
  duration,
}: ModalComponentProps<void> & { message: string; duration: number }) => {
  useEffect(() => {
    setTimeout(onResolve, duration);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        textAlign: "center",
        background: "#fccc",
        zIndex: 1000,
      }}
    >
      {message}
    </div>
  );
};
export const useToost = () => {
  return useModal<{ message: string; duration: number }>(toost);
};
