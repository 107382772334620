const isLocal = location.hostname === "localhost";
const _isAdminDomain = location.hostname.startsWith("admin");
export const isAdminDomain = isLocal || _isAdminDomain || undefined;
// export const isClientDomain = isLocal || !_isAdminDomain || undefined;

export const clientDomain = (() => {
  if (!isAdminDomain) {
    return `${window.location.protocol}//${window.location.host}`;
  } else {
    return `${window.location.protocol}//${window.location.host.replace(
      /^admin\./,
      ""
    )}`;
  }
})();

export const adminDomain = (() => {
  if (isAdminDomain) {
    return `${window.location.protocol}//${window.location.host}`;
  } else {
    return `${window.location.protocol}//admin.${window.location.host}`;
  }
})();

export const currentDomain = () => {
  return `${window.location.protocol}//${window.location.host}`;
};
