import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "取得日時",
      propertyName: "createdAt",
      schema: { schemaType: "datetime", readOnly: true },
    },
  ],
};
export default schema;
