import React, { useContext, useState } from "react";
import { IntlProvider } from "react-intl";
import {
  availableLocales,
  browserLocale,
  Locale,
  messages,
} from "utils/locale";

const defaultLocale = availableLocales.find(
  (locale) => locale === browserLocale
)
  ? (browserLocale as Locale)
  : "en";

const SetLocaleContext = React.createContext(
  null as React.Dispatch<React.SetStateAction<Locale>> | null
);

export const useSetLocale = () => {
  return useContext(SetLocaleContext) as React.Dispatch<
    React.SetStateAction<Locale>
  >;
};

const LanguageWrapper: React.FC = ({ children }) => {
  const [locale, setLocale] = useState(defaultLocale);
  return (
    <SetLocaleContext.Provider value={setLocale}>
      <IntlProvider
        locale={locale}
        defaultLocale="en"
        messages={messages[locale]}
      >
        {children}
      </IntlProvider>
    </SetLocaleContext.Provider>
  );
};

export default LanguageWrapper;
