import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const parse = require("loose-json");

interface EnqueteSchema extends BaseSchema {
  schemaType: "enquete";
  required?: boolean;
}

const EnqueteSchemaComponent: SchemaComponent<EnqueteSchema> = (
  props: SchemaComponentInternalProps<EnqueteSchema>
) => {
  const { value, registerProps, fieldState } = useField(props, [
    requiredValidator,
  ]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    const text = e.target.value as string;
    const lines = text.split("\n").map((line) => line.split("\t"));
    const header = lines.shift();
    if (!header) throw new Error();

    const properties = lines
      .map((line) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const property = { schema: {} } as Record<string, any>;
        line.forEach((item, index) => {
          if (item) {
            const key = header[index];
            if (key === "options") {
              property.schema[key] = item.split("/").map((item, index) => {
                const match = item.match(/(.*)#(.*)/);
                return match
                  ? {
                      title: match[1],
                      value: match[2],
                    }
                  : {
                      title: item,
                      value: index,
                    };
              });
            } else if (key === "params") {
              Object.assign(property.schema, parse(item));
            } else if (key === "title" || key === "propertyName") {
              property[key] = item;
            } else if (key === "required") {
              if (item) {
                property.schema[key] = true;
              }
            } else {
              property.schema[key] = item;
            }
          }
        });
        return property;
      })
      .filter((item) => item.schema.schemaType);
    console.log("properties", properties);
    registerProps.onChange({ schemaType: "object", properties });
  };
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        as="textarea"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        style={{ height: "160px" }}
        defaultValue={""}
        {...registerProps}
        onChange={onChange}
      />
    </FieldWrapper>
  );
};

EnqueteSchemaComponent.display = DisplayComponent("EnqueteDisplayComponent");

export default EnqueteSchemaComponent;
