import React from "react";
import schema from "./schema";
import SchemaListPage from "components/SchemaListPage";
import { useApiLogList } from "models/apiLog";

import { APILOGS } from "../route";
import { resolveRoute } from "pages";
import { Button } from "react-bootstrap";

export const LIST_APILOGS = APILOGS.sub("", ApiLogs);

function ApiLogs({
  campaignId,
  hashTagId,
}: {
  campaignId: string;
  hashTagId: string;
}) {
  const { list, loading, error, ref } = useApiLogList({
    campaignId,
    hashTagId,
  });
  console.log("error", error);
  const parameter = {
    title: "ログ一覧",
    edit: {
      path: (apiLogId: string) =>
        resolveRoute("UPDATE_APILOG", { apiLogId, campaignId, hashTagId }),
    },
    create: {
      path: () =>
        resolveRoute("UPDATE_APILOG", {
          campaignId,
          hashTagId,
          apiLogId: `${Date.now()}`,
        }),
    },
    schema,
  };
  // const startToRetrieve = async () => {
  //   ref.add({});
  // };
  return (
    <>
      <SchemaListPage
        parameter={parameter}
        list={list}
        loading={loading}
      ></SchemaListPage>
      {/* <section className="main-section">
        <Button onClick={startToRetrieve}>ただちに取得</Button>
      </section> */}
    </>
  );
}

export default ApiLogs;
