import {
  getFirestore,
  collection,
  CollectionReference,
  collectionGroup,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import {
  couponData,
  enqueteData,
  shopData,
  mailTemplateData,
  mailLogData,
  userData,
  clientData,
  adminUserData,
  surveyData,
  rewardData,
} from "./data";

const firestore = getFirestore(firebaseApp);

const getRootCollection = <T>(path: string) =>
  collection(firestore, path) as CollectionReference<T>;

export const couponCollection = getRootCollection<couponData>("coupon");
export const enqueteCollection = getRootCollection<enqueteData>("enquete");
export const shopCollection = getRootCollection<shopData>("shop");
export const surveyCollection = getRootCollection<surveyData>("survey");
export const rewardCollection = getRootCollection<rewardData>("reward");
export const mailTemplateCollection =
  getRootCollection<mailTemplateData>("mailTemplate");
export const mailLogCollection = getRootCollection<mailLogData>("mailLog");
export const userCollection = getRootCollection<userData>("user");
export const clientCollection = getRootCollection<clientData>("client");

export const mailTemplateCollectionGroup = collectionGroup(
  firestore,
  "mailTemplate"
);
