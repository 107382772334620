import dayjs from "dayjs";
import { useIntl } from "react-intl";

const useFormatter = () => {
  const intl = useIntl();
  const dateFormatter = (
    id: string,
    defaultMessage: string,
    value: number | null | undefined
  ) => {
    const dateFormat = intl.formatMessage({ id, defaultMessage });
    return value && dayjs(value).format(dateFormat);
  };
  return {
    formatDate: dateFormatter.bind(null, "DateFormat", "YYYY-MM-DD"),
    formatDateTime: dateFormatter.bind(
      null,
      "DateTimeFormat",
      "YYYY-MM-DD HH:mm:ss"
    ),
    formatMessage: (id: string, values = {}) => {
      return intl.formatMessage({ id }, values);
    },
  };
};

export { useFormatter };
