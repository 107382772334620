import React, { useState, useCallback, useEffect } from "react";
import classNames from "classnames";
import { useFormatter } from "hooks/intl";
import defaultLogo from "assets/user-default-log.svg";
import { useDomEvent } from "hooks/layout";
import { useAuthentication, signOut } from "hooks/auth";

const NavBar = ({
  handleMobileToggle,
  asideMobileActive,
}: {
  handleMobileToggle: React.MouseEventHandler<HTMLAnchorElement> | undefined;
  asideMobileActive: boolean;
}) => {
  const { formatMessage } = useFormatter();
  const [navMobileActive, setNavMobileActive] = useState(false);

  const { data: user } = useAuthentication();
  const { displayName: userName, photoURL: logoUrl } = user || {};

  const onClickLogoutHandler = () => {
    signOut();
  };

  const onMobileToggleHandler = useCallback(() => {
    setNavMobileActive(!navMobileActive);
  }, [setNavMobileActive, navMobileActive]);

  useDomEvent(window, ["resize", "scroll"], () => {
    setNavMobileActive(false);
  });

  return (
    <nav
      id="navbar-main"
      className={classNames("navbar", asideMobileActive && "has-aside")}
    >
      <div className="navbar-brand">
        <a
          className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
          onClick={handleMobileToggle}
        >
          <span className="icon">
            <i
              className={classNames(
                "mdi",
                "mdi-24px",
                { "mdi-forwardburger": !asideMobileActive },
                { "mdi-backburger": asideMobileActive }
              )}
            />
          </span>
        </a>
      </div>
      <div className="navbar-brand is-right">
        <a
          className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
          data-target="navbar-menu"
          onClick={onMobileToggleHandler}
        >
          <span className="icon">
            <i
              className={classNames(
                "mdi",
                { "mdi-dots-vertical": !navMobileActive },
                { "mdi-close": navMobileActive }
              )}
            />
          </span>
        </a>
      </div>
      <div
        className={classNames("navbar-menu", {
          "is-active": navMobileActive,
        })}
        id="navbar-menu"
      >
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <img src={logoUrl || defaultLogo} alt="User profile" />
              </div>
              <div className="is-user-name">
                <span>{userName}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down" />
              </span>
            </a>
            <div className="navbar-dropdown">
              <hr className="navbar-divider" />
              <a
                className="navbar-item"
                id="logout"
                onClick={onClickLogoutHandler}
              >
                <span className="icon">
                  <i className="mdi mdi-logout" />
                </span>
                <span>{formatMessage("NavBar.logOut")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
