import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import NavBar from "../Navigation/NavBar";
import Aside from "../Navigation/Aside";
import Footer from "../Navigation/Footer";

import classes from "./Layout.module.scss";
import { useDomEvent } from "hooks/layout";
import classNames from "classnames";
import { Loading } from "components/Loading";
import { Navigate, useParams } from "react-router-dom";
import { useAdminAuthentication } from "hooks/auth";
import { resolveRoute } from "pages";
import { useTracker } from "utils/analytics";
import { useFacebookAccount } from "pages/admin/profile/link";
import NotFound from "pages/notFound";

// eslint-disable-next-line @typescript-eslint/ban-types
const AdminLayout = ({ children }: PropsWithChildren<object>) => {
  useTracker();
  useFacebookAccount();

  const [asideMobileActive, setAsideMobileActive] = useState(false);
  const changeAsideMobileActive = useCallback((flag: boolean) => {
    document.documentElement.classList.toggle(
      "has-aside-mobile-expanded",
      flag
    );
    setAsideMobileActive(flag);
  }, []);

  const toggleAsideMobileActive = useCallback(() => {
    changeAsideMobileActive(!asideMobileActive);
  }, [asideMobileActive]);

  useDomEvent(window, ["resize", "scroll"], () => {
    changeAsideMobileActive(false);
  });

  const mainRef = useRef(null as null | HTMLDivElement);
  useEffect(() => {
    if (mainRef.current && asideMobileActive) {
      const element = mainRef.current;
      const closeHandler = () => {
        changeAsideMobileActive(false);
      };
      element.addEventListener("click", closeHandler);
      return () => {
        element.removeEventListener("click", closeHandler);
      };
    }
  }, [mainRef.current, asideMobileActive]);

  const { loading, isAdmin, user } = useAdminAuthentication();
  const { campaignId } = useParams<{ campaignId: string }>();
  const { campaignIds, role } = user || {};

  if (loading) {
    return <Loading />;
  }
  if (!isAdmin) {
    return <Navigate to={resolveRoute("LOGIN")} replace />;
  }
  if (role !== "master" && campaignId && !campaignIds?.includes(campaignId)) {
    return <NotFound></NotFound>;
    // return <Navigate to={resolveRoute("ADMIN_HOME")} replace />;
  }

  return (
    <>
      <NavBar
        handleMobileToggle={toggleAsideMobileActive}
        asideMobileActive={asideMobileActive}
      />
      <div
        onClickCapture={() =>
          setImmediate(() => changeAsideMobileActive(false))
        }
      >
        <Aside asideMobileActive={asideMobileActive} />
      </div>
      <div
        onClickCapture={(e) => {
          if (asideMobileActive) {
            e.preventDefault();
            setImmediate(() => changeAsideMobileActive(false));
          }
        }}
        className={classNames(classes.layout)}
      >
        {children}
      </div>
      <Footer />
    </>
  );
};

export default AdminLayout;
