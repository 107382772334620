import {
  getFirestore,
  collection,
  doc,
  orderBy,
  query,
  where,
  documentId,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { createDocumentHook, createQueryHook } from "./hook";
const firestore = getFirestore(firebaseApp);

export const campaignCollection = () => {
  return collection(firestore, `/campaign`);
};

export const useCampaignDoc = createDocumentHook((campaignId?: string) => {
  const collection = campaignCollection();
  return [campaignId ? doc(collection, campaignId) : doc(collection)];
}, "id");

export const useCampaignList = createQueryHook(
  (campaignIds?: string[]) => {
    const collection = campaignCollection();
    return [
      query(
        collection,
        ...(campaignIds
          ? [where(documentId(), "in", campaignIds)]
          : [orderBy("createdAt", "desc")])
      ),
      collection,
    ];
  },
  "id",
  "createdAt"
);
