import { Schema } from "schemaComponents";

const getSchema = ({
  instagramUsers,
  linkToFacebookAccount,
}: {
  isEditing: boolean;
  linkToFacebookAccount?: boolean;
  instagramUsers?: { title: string; value: string }[];
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "Facebookアカウント",
        propertyName: "linkToFacebookAccount",
        schema: {
          schemaType: "boolean",
          uiType: "switch",
        },
      },
      ...(linkToFacebookAccount
        ? ([
            {
              title: "Facebookアカウント最終更新日時",
              propertyName: "facebook.updatedAt",
              schema: {
                schemaType: "datetime",
                readOnly: true,
              },
            },
            {
              title: "インスタグラムユーザ",
              propertyName: "instagramUserId",
              schema: {
                schemaType: "selector",
                options: instagramUsers || [],
              },
            },
          ] as const)
        : []),
    ],
  };
};
export default getSchema;
