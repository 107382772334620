import {
  doc,
  query,
  orderBy,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Query,
  where,
} from "firebase/firestore";
import {
  createQueryRefHook,
  createDocumentRefHook,
} from "@smart-hook/react-smart-hook-firebase";
import { createDocumentHook, createQueryHook } from "../utils/firestore";

import {
  clientCollection,
  couponCollection,
  enqueteCollection,
  mailLogCollection,
  mailTemplateCollection,
  rewardCollection,
  shopCollection,
  surveyCollection,
} from "models/collection";
export { createDocumentHook, createQueryHook } from "../utils/firestore";

const withIdConverter = <D>(): FirestoreDataConverter<D & { id: string }> => {
  return {
    toFirestore: (d) => d,
    fromFirestore: (snapshot: QueryDocumentSnapshot<D>) => {
      return { ...snapshot.data(), id: snapshot.id };
    },
  };
};

export const useDocumentData = createDocumentRefHook();
export const useQueryData = createQueryRefHook();
export const useQueryDataWithId = <D>(query: Query<D>) => {
  return useQueryData(query.withConverter(withIdConverter<D>()));
};

export const useClientDoc = createDocumentHook(
  (clientId?: string) => [
    clientId ? doc(clientCollection, clientId) : doc(clientCollection),
  ],
  "id"
);

export const useShopDoc = createDocumentHook(
  ({ shopId, clientId }: { shopId?: string; clientId?: string }) => [
    shopId ? doc(shopCollection, shopId) : doc(shopCollection),
    { clientId },
  ],
  "id"
);

export const useSurveyDoc = createDocumentHook(
  ({ surveyId, clientId }: { surveyId?: string; clientId?: string }) => [
    surveyId ? doc(surveyCollection, surveyId) : doc(surveyCollection),
    { clientId },
  ],
  "id"
);

export const useRewardDoc = createDocumentHook(
  ({ rewardId, clientId }: { rewardId?: string; clientId?: string }) => [
    rewardId ? doc(rewardCollection, rewardId) : doc(rewardCollection),
    { clientId },
  ],
  "id"
);

export const useEnqueteDoc = createDocumentHook(
  ({ enqueteId, clientId }: { enqueteId?: string; clientId?: string }) => [
    enqueteId ? doc(enqueteCollection, enqueteId) : doc(enqueteCollection),
    { clientId },
  ],
  "id"
);

export const useCouponDoc = createDocumentHook(
  ({ couponId, clientId }: { couponId?: string; clientId?: string }) => [
    couponId ? doc(couponCollection, couponId) : doc(couponCollection),
    { clientId },
  ],
  "id"
);

export const useMailTemplateDoc = createDocumentHook(
  ({
    clientId,
    mailTemplateId,
  }: {
    clientId: string;
    mailTemplateId: string;
  }) => {
    return [
      mailTemplateId
        ? doc(
            mailTemplateCollection,
            clientId ? `${clientId}:${mailTemplateId}` : mailTemplateId
          )
        : doc(mailTemplateCollection),
    ];
  },
  "id"
);

export const useMailLogDoc = createDocumentHook(
  (mailLogId?: string) => [
    mailLogId ? doc(mailLogCollection, mailLogId) : doc(mailLogCollection),
  ],
  "id"
);

export const useClientList = createQueryHook(
  () => {
    return [
      query(clientCollection, orderBy("createdAt", "desc")),
      clientCollection,
    ];
  },
  "id",
  "createdAt"
);

export const useEnqueteList = createQueryHook(
  ({ clientId }: { clientId: string }) => {
    return [
      query(
        enqueteCollection,
        where("clientId", "==", clientId),
        orderBy("createdAt", "desc")
      ),
      enqueteCollection,
      { clientId },
    ];
  },
  "id",
  "createdAt"
);

export const useRewardList = createQueryHook(
  ({ clientId }: { clientId: string }) => {
    return [
      query(
        rewardCollection,
        where("clientId", "==", clientId),
        orderBy("createdAt", "desc")
      ),
      rewardCollection,
      { clientId },
    ];
  },
  "id",
  "createdAt"
);

export const useSurveyList = createQueryHook(
  ({ clientId }: { clientId: string }) => {
    return [
      query(
        surveyCollection,
        where("clientId", "==", clientId),
        orderBy("createdAt", "desc")
      ),
      surveyCollection,
      { clientId },
    ];
  },
  "id",
  "createdAt"
);

export const useCouponList = createQueryHook(
  ({ clientId }: { clientId: string }) => {
    return [
      query(
        couponCollection,
        where("clientId", "==", clientId),
        orderBy("createdAt", "desc")
      ),
      couponCollection,
      { clientId },
    ];
  },
  "id",
  "createdAt"
);
export const useShopList = createQueryHook(
  ({ clientId }: { clientId: string }) => {
    return [
      query(
        shopCollection,
        where("clientId", "==", clientId),
        orderBy("createdAt", "desc")
      ),
      shopCollection,
      { clientId },
    ];
  },
  "id",
  "createdAt"
);
