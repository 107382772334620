import React from "react";

import { Link } from "react-router-dom";
import NavLink from "components/Navigation/Link";
import { useAdminAuthentication } from "hooks/auth";
import { useCampaignDoc } from "models/campaign";
import { resolveRoute } from "pages";

export const CampaignMenu = ({ campaignId }: { campaignId: string }) => {
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { data } = useCampaignDoc(campaignId);
  if (!campaignId) return <div>Internal error</div>;
  return (
    <>
      <Link
        to={resolveRoute("UPDATE_CAMPAIGN", { campaignId })}
        className="aside-tools"
      >
        <div className="aside-tools-label">{data?.campaignName}</div>
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          <li>
            <NavLink to={resolveRoute("LIST_CAMPAIGNS")}>
              <span className="icon">
                <i className="mdi mdi-backburger" />
              </span>
              <span className="menu-item-label">戻る</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_MEDIAS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">投稿一覧</span>
            </NavLink>
          </li>
          {isMaster && (
            <>
              <li>
                <NavLink to={resolveRoute("UPDATE_CAMPAIGN", { campaignId })}>
                  <span className="icon">
                    <i className="mdi mdi-account-supervisor" />
                  </span>
                  <span className="menu-item-label">キャンペーン編集</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={resolveRoute("LIST_HASHTAGS", { campaignId })}>
                  <span className="icon">
                    <i className="mdi mdi-account-supervisor" />
                  </span>
                  <span className="menu-item-label">ハッシュタグ一覧</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={resolveRoute("LIST_CUSTOMTAGS", { campaignId })}>
                  <span className="icon">
                    <i className="mdi mdi-account-supervisor" />
                  </span>
                  <span className="menu-item-label">カスタムタグ一覧</span>
                </NavLink>
              </li>

              <li>
                <NavLink to={resolveRoute("LIST_APIACCOUNTS", { campaignId })}>
                  <span className="icon">
                    <i className="mdi mdi-account-supervisor" />
                  </span>
                  <span className="menu-item-label">アカウント一覧</span>
                </NavLink>
              </li>
            </>
          )}

          <li>
            <NavLink to={resolveRoute("PUBLISH", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">外部公開</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={resolveRoute("LIST_SHOPS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">店舗一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_COUPONS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">クーポン一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_ENQUETES", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">アンケート一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_SURVEYS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">調査一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_REWARDS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">ハッシュタグ一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_MAIL_TEMPLATES", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">メールテンプレート</span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </>
  );
};
