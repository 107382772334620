import {
  getFirestore,
  collection,
  doc,
  orderBy,
  query,
  CollectionReference,
  where,
  updateDoc,
  deleteField,
  setDoc,
  writeBatch,
  FieldPath,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { string } from "yup";
import { mediaModel } from "./data";
import { createDocumentHook, createQueryHook } from "./hook";
import chunk from "lodash/chunk";

const firestore = getFirestore(firebaseApp);

export const mediaCollection = ({ campaignId }: { campaignId: string }) => {
  return collection(
    firestore,
    `/campaign/${campaignId}/media`
  ) as CollectionReference<mediaModel>;
};

export const useMediaDoc = createDocumentHook(
  ({ campaignId, mediaId }: { campaignId: string; mediaId?: string }) => {
    const collection = mediaCollection({ campaignId });
    return [mediaId ? doc(collection, mediaId) : doc(collection)];
  },
  "userId"
);

export const useMediaList = createQueryHook(
  ({ campaignId }: { campaignId: string }) => {
    const collection = mediaCollection({ campaignId });
    return [query(collection), collection];
  },
  "id",
  "createdAt"
);

export const setCustomTag = async (
  { campaignId }: { campaignId: string },
  id: string,
  tagId: string,
  value: number | undefined | boolean
) => {
  const collection = mediaCollection({ campaignId });
  await setDoc(
    doc(collection, id),
    {
      customTag: {
        [tagId]:
          value === false || value === undefined
            ? deleteField()
            : value === true
            ? 1
            : value,
      },
    },
    { merge: true }
  );
};

export const setCustomTagsBulk = async (
  { campaignId }: { campaignId: string },
  items: {
    mediaId: string;
    customeTags: Record<string, number | undefined | true | FieldPath>;
  }[]
) => {
  const collection = mediaCollection({ campaignId });
  for (const chunkedItems of chunk(items, 500)) {
    const batch = writeBatch(firestore);
    for (const item of chunkedItems) {
      batch.set(
        doc(collection, item.mediaId),
        { customTag: item.customeTags },
        { merge: true }
      );
    }
    await batch.commit();
  }
};
