import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useApiAccountDoc } from "models/apiAccount";
import { APIACCOUNT, APIACCOUNTS } from "../route";
import { resolveRoute } from "pages";

export const UPDATE_APIACCOUNT = APIACCOUNT.sub("", ApiAccount);
export const ADD_APIACCOUNT = APIACCOUNTS.sub("/new", ApiAccount);

function ApiAccount({
  apiAccountId,
  campaignId,
}: {
  apiAccountId?: string;
  campaignId: string;
}) {
  const isEditing = !!apiAccountId;

  const { data, loading, ref } = useApiAccountDoc({ apiAccountId, campaignId });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "アカウント編集" : "アカウント新規作成",
    edit: {
      title: `${isEditing ? "編集" : "作成"}`,
      confirm: `${isEditing ? "編集" : "作成"}しますか？`,
      handler: ref.set,
    },
    remove: isEditing ? { handler: ref.delete } : undefined,
    schema: getSchema({ isEditing }),
    backPath: resolveRoute("LIST_APIACCOUNTS", { campaignId }),
  };
  return (
    <SchemaFormPage
      data={isEditing ? data : {}}
      parameter={parameter}
      loading={isEditing && loading}
    />
  );
}

export default ApiAccount;
