import { useToost } from "hooks/dialogs";
import { customTagModel } from "models/data";
import React, { CSSProperties } from "react";
import { Schema, PropertyType } from "schemaComponents";
import { currentDomain } from "utils/domain";

export default ({
  isEditing,
  campaignId,
  customTagList,
}: {
  campaignId: string;
  isEditing: boolean;
  customTagList:
    | (customTagModel & {
        id: string;
      })[]
    | undefined;
}): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "ハッシュタグ",
        propertyName: "hashTags",
        schema: {
          schemaType: "multipleExternalKey",
          resourcePath: `/campaign/${campaignId}/hashTag`,
          titleKey: "hashTagName",
        },
      },
      {
        title: "カスタムタグ",
        propertyName: "customTags",
        schema: {
          schemaType: "multipleSelector",
          options:
            customTagList
              ?.filter((item) => !item.options)
              .map((item) => {
                return {
                  title: item.customTagName,
                  value: item.id,
                };
              }) || [],
        },
      },
      ...(customTagList
        ?.filter((item) => item.options)
        .map(
          (item): PropertyType => ({
            title: item.customTagName,
            propertyName: `customTagValues.${item.id}`,
            schema: {
              schemaType: "selector" as const,
              options: item.options || [],
            },
          })
        ) || []),
      {
        title: "コード",
        propertyId: "code",
        schema: {
          schemaType: "message",
          text: ({ value }) => {
            const condition = Object.fromEntries([
              ...(value.customTags?.map((item: string) => [item, 1]) || []),
              ...(value.hashTags?.map((item: string) => [item, 1]) || []),
              ...Object.entries(value.customTagValues).filter(
                ([key, value]) => value !== null
              ),
            ]);
            const params = { campaignId: value.id, params: condition };

            const code = `
<script src="${currentDomain()}/media.js"></script>
<script>
fancrewCampaign.getMediaData(${JSON.stringify(params)}).then(items => {
  console.log(items);
})
</script>
`;
            const style: CSSProperties = {
              whiteSpace: "pre-wrap",
              // height: "200px",
              // overflowY: "scroll",
              background: "#eee",
              padding: "10px",
              position: "relative",
            };
            const toost = useToost();
            return (
              <div>
                <div style={style}>
                  {code.trim()}
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      copyToClipboard(code);
                      toost({
                        message: "クリップボードにコピーしました",
                        duration: 1000,
                      });
                    }}
                  >
                    <span className="icon">
                      <i className="mdi mdi-content-copy" />
                    </span>
                  </div>
                </div>

                <a
                  target="_blank"
                  href={`https://asia-northeast1-fancrew-campaign.cloudfunctions.net/sample/${
                    value.id
                  }?${new URLSearchParams({
                    ...condition,
                    baseUrl: currentDomain(),
                  })}`}
                  rel="noreferrer"
                >
                  Preview
                </a>
              </div>
            );
          },
        },
      },
    ],
  };
};

const copyToClipboard = (str: string) => {
  const listener = (e: any) => {
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
    document.removeEventListener("copy", listener);
  };
  document.addEventListener("copy", listener);
  document.execCommand("copy");
};
