import React from "react";
import {
  SchemaComponentInternalProps,
  SchemaComponent,
  joinFieldPath,
  BaseSchema,
} from "react-hook-schema-form";

import { Schema, Field } from "..";

interface PropertyParameter {
  propertyName?: string;
  propertyId?: string;
  title: string;
  schema: Schema;
  noLabel?: boolean;
}

interface ObjectSchema extends BaseSchema {
  schemaType: "object";
  properties: PropertyParameter[];
  outputOptions?: {
    useRealFields?: boolean;
  };
}

const Property = ({
  fieldPath,
  readOnly,
  control,
  property,
}: SchemaComponentInternalProps<BaseSchema> & {
  property: PropertyParameter;
}) => {
  const { title, propertyName, schema, noLabel } = property;
  const content = (
    <Field
      control={control}
      fieldPath={joinFieldPath(fieldPath, propertyName)}
      readOnly={readOnly}
      schema={schema}
      label={title}
    />
  );
  if (noLabel) {
    return content;
  } else
    return (
      <div className="field-pair" key={0}>
        <div className="field-label">
          <label className="label">
            <span
              style={{
                verticalAlign: "middle",
              }}
            >
              {title}
            </span>
            {schema.required && !readOnly && !schema.readOnly && (
              <span
                style={{
                  verticalAlign: "middle",
                  marginLeft: "0.2em",
                  border: "1px solid #999",
                  padding: "0em 0.3em",
                  color: "#999",
                  fontSize: "0.8em",
                  borderRadius: "3px",
                  whiteSpace: "nowrap",
                }}
              >
                必須
              </span>
            )}
          </label>
        </div>
        <div className="field-content">{content}</div>
      </div>
    );
};

const ObjectSchemaComponent: SchemaComponent<ObjectSchema> = ({
  fieldPath,
  readOnly,
  control,
  schema,
}: SchemaComponentInternalProps<ObjectSchema>) => {
  const properties = schema.properties;
  return (
    <>
      {properties?.map((property, index) => (
        <Property
          schema={property.schema}
          control={control}
          readOnly={readOnly}
          fieldPath={fieldPath}
          // key={index}
          key={String(property.propertyId || property.propertyName)}
          property={property}
        />
      ))}
    </>
  );
};

export default ObjectSchemaComponent;
