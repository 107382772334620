import React from "react";
import schema from "./schema";
import SchemaListPage from "components/SchemaListPage";
import { useAdminUserList } from "models/adminUser";
import { resolveRoute } from "pages";
import { ADMIN_USERS } from "..";

export const LIST_ADMIN_USER = ADMIN_USERS.sub("", AdminUsers);

function AdminUsers() {
  const { list, loading } = useAdminUserList();
  const parameter = {
    title: "管理ユーザ一覧",
    edit: {
      path: (adminUserId: string) =>
        resolveRoute("UPDATE_ADMIN_USER", { adminUserId }),
    },
    create: { path: () => resolveRoute("CREATE_ADMIN_USER") },
    schema,
  };
  return (
    <>
      <SchemaListPage
        parameter={parameter}
        list={list}
        loading={loading}
      ></SchemaListPage>
    </>
  );
}

export default AdminUsers;
