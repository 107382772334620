import React, { useCallback, useState } from "react";
import { useFormatter } from "hooks/intl";
import Table, { ExtendedColumn } from "components/Table";
import { propertiesToColumns } from "./converter";
import { Link } from "react-router-dom";
import { Value } from "react-hook-schema-form";
import { ObjectSchema } from "schemaComponents";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { Stack } from "react-bootstrap";
import { useDownload } from "hooks/dialogs";
import { arrayToCsv } from "./csvUtil";

interface SchemaListPageParameter {
  title: string;
  edit: { path: (id: string) => string };
  create?: { path: () => string };
  schema: ObjectSchema;
}

const SchemaListPage = ({
  parameter,
  list,
  loading,
}: {
  parameter: SchemaListPageParameter;
  list?: Value[];
  loading?: boolean;
}) => {
  const { schema, title, edit, create } = parameter;
  const editPath = edit.path;
  const createPath = create?.path;
  const [search, setSearch] = useState("");
  const searchString = (search || "").toLowerCase();
  const searchNumber = parseInt(search, 10);
  const filteredList = search
    ? list?.filter((item) => {
        return Object.values(item).some((field) => {
          if (!field) {
            return null;
          } else if (typeof field === "number") {
            return field === searchNumber;
          } else if (
            typeof field === "number" ||
            typeof field === "string" ||
            typeof field === "boolean" ||
            (typeof field === "object" && field)
          ) {
            return field.toString().toLowerCase().includes(searchString);
          } else {
            return null;
          }
        });
      })
    : list;
  const downloadHandler = useCallback(
    (e) => {
      e.preventDefault();
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      download({
        data: [bom, arrayToCsv(list || [], schema)],
        mimeType: "text/csv",
      });
    },
    [list]
  );

  const columns = [
    {
      Header: "",
      id: "actions",
      accessor: "actions",
      Cell: ({ row }) => {
        return (
          <Link to={editPath(row.original.id)}>
            <Button size="sm">
              <i className="mdi mdi-link" />
            </Button>
          </Link>
        );
      },
      disableSortBy: true,
    } as ExtendedColumn<Value>,
    ...propertiesToColumns(schema.properties),
  ];
  const download = useDownload();
  return (
    <>
      <section className="hero">
        <div className="hero-body">
          <Stack direction="horizontal" gap={3}>
            <h1 className="title">{title}</h1>

            <div className="ms-auto">
              {createPath && (
                <Link to={createPath()}>
                  <Button variant="outline-secondary">新規作成</Button>
                </Link>
              )}{" "}
              <Button onClick={downloadHandler} variant="outline-secondary">
                ダウンロード
              </Button>
            </div>
          </Stack>
        </div>
      </section>
      <section>
        {loading || !filteredList ? (
          <div style={{ padding: "20px" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="card">
            <Form.Group as={Row} style={{ padding: "10px 20px 20px 20px" }}>
              <Form.Label column sm="2">
                検索条件
              </Form.Label>
              <Col sm="6">
                <Form.Control
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
            </Form.Group>
            <div className="b-table">
              <Table columns={columns} data={filteredList} />
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default SchemaListPage;
