import React from "react";
import { Link } from "react-router-dom";
import { Navigate } from "react-router";
import { useMailAuth } from "hooks/auth";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import ErrorMessage from "components/ErrorMessage";

import { CONSUMER } from "..";
import ConsumerLayout from "components/ConsumerLayout";
import { resolveRoute } from "pages";

export const CONSUMER_MAIL_AUTH = CONSUMER.child("/mailAuth", ConsumerMailAuth);

function ConsumerMailAuth({ shopId }: { shopId: string }) {
  const { error, loading, data } = useMailAuth({ url: location.href });
  if (data) {
    return (
      <Navigate
        replace
        to={resolveRoute("CONSUMER_ENQUETE", { shopId })}
      ></Navigate>
    );
  }
  return (
    <ConsumerLayout shopId={shopId} skipAuthentication>
      <div className="wide-form">
        <section className="hero">
          <div className="hero-body">
            <h1 className="title">メールリンク認証</h1>
            <div>
              {loading && (
                <div style={{ padding: "20px" }}>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
            </div>
          </div>
        </section>

        <section className="main-section adjust-width">
          {error && (
            <Alert variant="danger">
              <div>
                認証に失敗しました。
                <Link to={resolveRoute("CONSUMER_ENTRY", { shopId })}>
                  こちら
                </Link>
                からやり直してください。
              </div>
              <div>
                <ErrorMessage error={error} />
              </div>
            </Alert>
          )}
        </section>
      </div>
    </ConsumerLayout>
  );
}
export default ConsumerMailAuth;
