/* eslint-disable camelcase */
import { carouselMediaItem, mediaItem } from "models/mediaItem";

export type PagedResponse<T> = {
  data: T[];
  paging?: { cursors: { before: string; after: string }; next?: string };
};

const mergeUrlParams = (
  path: string,
  params?: Record<string, string | number | undefined | null>
) => {
  const originalUrl = new URL(path);
  const searchParams = new URLSearchParams(originalUrl.searchParams);
  for (const [key, value] of Object.entries(params || {})) {
    if (value != null) {
      searchParams.set(key, `${value}`);
    } else {
      searchParams.delete(key);
    }
  }
  const searchString = searchParams.toString();
  return `${originalUrl.protocol}//${originalUrl.host}${originalUrl.pathname}${
    searchString ? "?" : ""
  }${searchString}`;
};

export const fetchJson = async <T>(
  path: string,
  params?: Record<string, string | number | undefined | null>
) => {
  const url = mergeUrlParams(path, params);
  console.log("url", url);
  const fetchResponse = await fetch(url);
  return fetchResponse.json() as Promise<T>;
};

const api = async <T>(path: string, params: Record<string, string>) => {
  return fetchJson<T>(`https://graph.facebook.com/v14.0${path}`, params);
};

export const iterate = async <R>(
  handler: () => Promise<PagedResponse<R>>,
  callback: (data: R[]) => void,
  limit: number
) => {
  const response = await handler();
  const next = async (response: PagedResponse<R>, limit: number) => {
    callback(response.data);
    if (response.paging?.next && limit > 0) {
      const nextResponse = await fetchJson<PagedResponse<R>>(
        response.paging?.next
      );
      next(nextResponse, limit - 1);
    }
  };
  next(response, limit);
};

export class UserApi {
  protected access_token;
  constructor({ access_token }: { access_token: string }) {
    this.access_token = access_token;
  }

  api<T>(path: string, params: Record<string, string>) {
    return api<T>(path, {
      ...params,
      access_token: this.access_token,
    });
  }

  myAccounts() {
    return this.api<
      PagedResponse<{
        id: string;
        name: string;
        category: string;
        instagram_business_account: { id: string };
      }>
    >("/me/accounts", {
      fields: "instagram_business_account,category,name,picture",
    });
  }
}

export class InstagramApi {
  protected user_id;
  protected access_token;
  constructor({
    access_token,
    user_id,
  }: {
    access_token: string;
    user_id: string;
  }) {
    this.access_token = access_token;
    this.user_id = user_id;
  }

  api<T>(path: string, params: Record<string, string>) {
    return api<T>(path, {
      ...params,
      user_id: this.user_id,
      access_token: this.access_token,
    });
  }

  topMedia({ hashTagId }: { hashTagId: string }) {
    return this.api<PagedResponse<carouselMediaItem | mediaItem>>(
      `/${hashTagId}/top_media`,
      {
        fields:
          "permalink,comments_count,like_count,media_type,media_url,timestamp,children{media_url,media_type}",
        limit: "50",
      }
    );
  }

  hashTagSearch({ hashTagName }: { hashTagName: string }) {
    return this.api<{ data: { id: string }[] }>(`/ig_hashtag_search`, {
      q: hashTagName,
    });
  }
}
