import React, { useEffect } from "react";
import { doc, setDoc } from "firebase/firestore";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { cleanObject } from "utils/firestore";
import { enqueteCollection } from "models/collection";
import { couponUrl } from "utils/coupon";
import { useAdminAuthentication } from "hooks/auth";
import { useClientDoc, useShopDoc, useSurveyDoc } from "models/hook";
import { usePolicy } from "hooks/dialogs";

import { CONSUMER } from "..";
import ConsumerLayout from "components/ConsumerLayout";

export const CONSUMER_ENQUETE = CONSUMER.child("/enquete", ConsumerEnquete);

function ConsumerEnquete({ shopId }: { shopId?: string }) {
  return (
    <ConsumerLayout shopId={shopId}>
      <ConsumerEnqueteInternal shopId={shopId} />
    </ConsumerLayout>
  );
}

function ConsumerEnqueteInternal({ shopId }: { shopId?: string }) {
  const { user, loading, error } = useAdminAuthentication();
  const { data: shopData } = useShopDoc({ shopId });
  const { data: surveyData } = useSurveyDoc({ surveyId: shopData?.surveyId });
  const { data: clientData } = useClientDoc(shopData?.clientId);
  const alert = usePolicy();
  useEffect(() => {
    if (clientData?.privacyPolicy) {
      alert({
        message: clientData?.privacyPolicy,
        title: "プライバシーポリシー",
      });
    }
  }, [clientData?.privacyPolicy]);
  const parameter: SchemaFormParameter | undefined = surveyData?.enquete && {
    title: "アンケート",
    edit: {
      title: "送信",
      handler: async (value) => {
        if (user && user.email && shopData && shopId && user.uid) {
          console.log("enqueteValue", value);
          const enqueteDoc = doc(enqueteCollection);
          cleanObject(value);
          await setDoc(enqueteDoc, {
            userId: user.uid,
            email: user.email,
            couponUrl: couponUrl(shopId, ":id"),
            createdAt: Date.now(),
            shopId,
            clientId: shopData.clientId,
            data: value,
            surveyId: shopData.surveyId,
          });
        }
      },
    },
    enableFormAlert: true,
    schema: surveyData?.enquete,
  };
  return (
    <>
      {parameter && (
        <div className={"wide-form"}>
          <SchemaFormPage data={{}} parameter={parameter} loading={loading} />
        </div>
      )}
    </>
  );
}
export default ConsumerEnquete;
