import {
  getFirestore,
  collection,
  doc,
  orderBy,
  query,
  CollectionReference,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { hashTagModel } from "./data";
import { createDocumentHook, createQueryHook } from "./hook";
const firestore = getFirestore(firebaseApp);

export const hashTagCollection = ({ campaignId }: { campaignId: string }) => {
  return collection(
    firestore,
    `/campaign/${campaignId}/hashTag`
  ) as CollectionReference<hashTagModel>;
};

export const useHashTagDoc = createDocumentHook(
  ({ campaignId, hashTagId }: { campaignId: string; hashTagId?: string }) => {
    const collection = hashTagCollection({ campaignId });
    return [
      hashTagId
        ? doc(collection, hashTagId)
        : (data: hashTagModel) => doc(collection, data.hashTagId),
    ];
  },
  "hashTagId"
);

export const useHashTagList = createQueryHook(
  ({ campaignId }: { campaignId: string }) => {
    const collection = hashTagCollection({ campaignId });
    return [query(collection, orderBy("createdAt", "desc")), collection];
  },
  "id",
  "createdAt"
);
