import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { CAMPAIGN, CAMPAIGNS } from "../route";
import { resolveRoute } from "pages";
import { useCampaignDoc } from "models/campaign";
import { useCustomTagList } from "models/customTag";

export const PUBLISH = CAMPAIGN.sub("/publish", Campaign);

function Campaign({ campaignId }: { campaignId: string }) {
  const isEditing = !!campaignId;
  const { data, loading, ref } = useCampaignDoc(campaignId);
  const { list: customTagList } = useCustomTagList({ campaignId });
  const parameter: SchemaFormParameter = {
    title: "外部公開",
    schema: getSchema({ campaignId, isEditing, customTagList }),
    backPath: resolveRoute("LIST_CAMPAIGNS"),
  };
  return (
    <SchemaFormPage
      data={{ ...data, customTagValues: {} }}
      parameter={parameter}
      loading={isEditing && loading}
    />
  );
}

export default Campaign;
