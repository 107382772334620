import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

const Link = ({
  children,
  to,
  className,
  onClick,
}: React.PropsWithChildren<{
  className?: string;
  noActiveStyle?: boolean;
  to: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}>) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(className || "navbar-item", isActive && "is-active")
      }
      onClick={onClick}
      end
    >
      {children}
    </NavLink>
  );
};

export default Link;
