import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { StyledFirebaseAuth } from "react-firebaseui";
import classes from "./Login.module.scss";
import { useAdminAuthentication, useResetPasswordAdmin } from "hooks/auth";
import Alert from "react-bootstrap/Alert";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  EmailAuthProvider,
} from "firebase/auth";
import firebaseApp from "firebaseApp";
import ErrorMessage from "components/ErrorMessage";
import { ADMIN as BASE } from "..";
import { resolveRoute } from "pages";
import Link from "components/Navigation/Link";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useToost } from "hooks/dialogs";

const auth = getAuth(firebaseApp);

export const REGISTER = BASE.sub("/register", Register);

function Register() {
  const [signInError, setSignInError] = useState(
    null as { message: string } | null
  );
  const [resetPassword] = useResetPasswordAdmin();
  const toost = useToost();
  const error = signInError;
  const parameter: SchemaFormParameter = {
    title: "パスワードリセット",
    schema: {
      schemaType: "object",
      properties: [
        {
          propertyName: "email",
          title: "メールアドレス",
          schema: { schemaType: "email" },
        },
      ],
    },
    edit: {
      title: "送信",
      handler: async (value) => {
        await resetPassword({
          email: value.email,
          path: resolveRoute("LOGIN"),
        });
        console.log("completed");
        await toost({
          message: "パスワードリセットのためのメールを送信しました",
          duration: 3000,
        });
        console.log("completed 2");
      },
      confirm: false,
    },
  };
  return (
    <>
      <div className="consumer-container">
        <section className="main-section">
          <SchemaFormPage data={{}} parameter={parameter} />
          {error && (
            <Alert variant="danger">
              <ErrorMessage error={error} />
            </Alert>
          )}
        </section>
      </div>
    </>
  );
}

export default Register;
