import React from "react";
import ReactDOM from "react-dom";

import LanguageWrapper from "components/LanguageWrapper";
import { ModalProvider } from "react-hooks-async-modal";
import { Routes } from "./pages";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/main.scss";
import "./assets/css/reset.scss";
import "./assets/css/field.scss";
import "./assets/css/level.scss";
import "./assets/css/hero.scss";
import "./assets/css/navbar.scss";

import reportWebVitals from "./reportWebVitals";

import "./utils/typedRoute";
import { BrowserRouter } from "react-router-dom";

const app = (
  <React.StrictMode>
    <LanguageWrapper>
      <BrowserRouter>
        <ModalProvider>
          <Routes />
        </ModalProvider>
      </BrowserRouter>
    </LanguageWrapper>
  </React.StrictMode>
);

ReactDOM.render(app, document.getElementById("root"));

reportWebVitals(console.log);
