import React, { useContext } from "react";
import {
  useParams,
  useRoutes,
  useNavigate,
  RouteObject,
  UNSAFE_RouteContext,
} from "react-router";
import { ParseUrlParams } from "typed-url-params";
import { compile } from "path-to-regexp";
import sortRoute from "sort-route-addresses";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyParams = any;
type NonEmptyString<T extends string> = T extends "" ? never : T;
type ParseUrlParamsSafe<P extends string> = string extends P
  ? AnyParams
  : ParseUrlParams<P>;

type SimpleFC<P> = (props: P) => JSX.Element;
type ComponentImpl<P extends string> = SimpleFC<ParseUrlParamsSafe<P>>;
type ComponentMap<P extends string> = Record<string, ComponentImpl<P>>;
type ExtendedPath<P extends string, SP extends string> = string extends SP
  ? string
  : `${P}${SP}`;

type Resolver<P> = (params: P) => string;
export type TypedRoute<
  P extends string,
  T = ParseUrlParams<P>
> = Resolver<T> & {
  type?: "abstract" | "parent";
  realPath: P;
  path: string;
  componentMap?: ComponentMap<string>;
  children: TypedRoute<string, AnyParams>[];
  dependencies: TypedRoute<string, AnyParams>[];
  sub<SP extends string, ST = ParseUrlParams<ExtendedPath<P, SP>>>(
    path: NonEmptyString<SP>,
    Component: ComponentImpl<ExtendedPath<P, SP>>,
    options: { parent: true; components?: ComponentMap<ExtendedPath<P, SP>> }
  ): ExtendedParentRoute<P, SP, T & ST>;
  sub<SP extends string, ST = ParseUrlParams<ExtendedPath<P, SP>>>(
    path: NonEmptyString<SP>,
    Component?: undefined,
    options?: {
      parent?: false;
      components?: ComponentMap<ExtendedPath<P, SP>>;
    }
  ): ExtendedAbstractRoute<P, SP, T & ST>;
  sub<SP extends string, ST = ParseUrlParams<ExtendedPath<P, SP>>>(
    path: NonEmptyString<SP>,
    Component: ComponentImpl<ExtendedPath<P, SP>>,
    options?: {
      parent?: false;
      components?: ComponentMap<ExtendedPath<P, SP>>;
    }
  ): ExtendedRoute<P, SP, T & ST>;
};

export type ParentRoute<P extends string, T = ParseUrlParams<P>> = TypedRoute<
  P,
  T
> & {
  type: "parent";
  child<SP extends string, ST = ParseUrlParams<ExtendedPath<P, SP>>>(
    path: NonEmptyString<SP>,
    Component: ComponentImpl<ExtendedPath<P, SP>>,
    options: { parent: true; components?: ComponentMap<ExtendedPath<P, SP>> }
  ): ExtendedParentRoute<P, SP, T & ST>;
  child<SP extends string, ST = ParseUrlParams<ExtendedPath<P, SP>>>(
    path: NonEmptyString<SP>,
    Component?: undefined,
    options?: {
      parent?: false;
      components?: ComponentMap<ExtendedPath<P, SP>>;
    }
  ): ExtendedAbstractRoute<P, SP, T & ST>;
  child<SP extends string, ST = ParseUrlParams<ExtendedPath<P, SP>>>(
    path: NonEmptyString<SP>,
    Component: ComponentImpl<ExtendedPath<P, SP>>,
    options?: {
      parent?: false;
      components?: ComponentMap<ExtendedPath<P, SP>>;
    }
  ): ExtendedRoute<P, SP, T & ST>;
  child(
    path: "",
    Component: ComponentImpl<P>,
    options: { parent: true }
  ): ParentRoute<P, T>;
  child(
    path: "",
    Component?: undefined,
    options?: { parent?: false }
  ): AbstractRoute<P, T>;
  child(
    path: "",
    Component: ComponentImpl<P>,
    options?: { parent?: false }
  ): TypedRoute<P, T>;
};
export type AbstractRoute<P extends string, T = ParseUrlParams<P>> = TypedRoute<
  P,
  T
> & {
  type: "abstract";
  sub(
    path: "",
    Component: ComponentImpl<P>,
    options: { parent: true }
  ): ParentRoute<P, T>;
  sub(
    path: "",
    Component: ComponentImpl<P>,
    options?: { parent?: false }
  ): TypedRoute<P, T>;
};
type paramsOfRoute<T> = T extends TypedRoute<string, infer P> ? P : never;
type ExtendedRoute<
  P extends string,
  SP extends string,
  T = ParseUrlParams<ExtendedPath<P, SP>>
> = string extends SP
  ? TypedRoute<string, T>
  : TypedRoute<ExtendedPath<P, SP>, T>;

type ExtendedAbstractRoute<
  P extends string,
  SP extends string,
  T = ParseUrlParams<ExtendedPath<P, SP>>
> = string extends SP
  ? AbstractRoute<string, T>
  : AbstractRoute<ExtendedPath<P, SP>, T>;

type ExtendedParentRoute<
  P extends string,
  SP extends string,
  T = ParseUrlParams<ExtendedPath<P, SP>>
> = string extends SP
  ? ParentRoute<string, T>
  : ParentRoute<ExtendedPath<P, SP>, T>;

const wrapComponent = <P extends string>(component: ComponentImpl<P>) => {
  return function WrappedComponent() {
    const params = useParams() as ParseUrlParamsSafe<P>;
    return component(params);
  };
};

function _typedRoute<P extends string, PP extends string = "">(
  path: P,
  componentParam?: ComponentMap<`${PP}${P}`>,
  base?: TypedRoute<PP, ParseUrlParams<PP>>,
  isChildren?: boolean,
  parent?: boolean
) {
  const paramComponentMap: ComponentMap<string> =
    componentParam as ComponentMap<string>;
  const componentMap: ComponentMap<string> = {
    ...base?.componentMap,
    ...paramComponentMap,
  };
  // Don't inherit default component
  if (!paramComponentMap?.default) {
    delete componentMap.default;
  }
  const realPath = ((base?.realPath || "") + path) as `${PP}${P}`;
  const route: TypedRoute<
    `${PP}${P}`,
    ParseUrlParams<`${PP}${P}`>
  > = Object.assign(
    (realPath.includes("*") ? () => "" : compile(realPath)) as Resolver<
      ParseUrlParamsSafe<`${PP}${P}`>
    >,
    {
      componentMap,
      path,
      type: !componentMap?.default
        ? ("abstract" as const)
        : parent
        ? ("parent" as const)
        : undefined,
      realPath,
      children: [],
      dependencies: [],
      sub: (<SP extends string>(
        path: SP,
        Component?: ComponentImpl<`${PP}${P}${SP}`>,
        {
          parent,
          components,
        }: { parent: boolean; components?: ComponentMap<`${PP}${P}${SP}`> } = {
          parent: false,
        }
      ) => {
        return _typedRoute<SP, `${PP}${P}`>(
          path,
          {
            ...components,
            ...(Component && { default: Component }),
          },
          route,
          false,
          parent
        );
      }) as AnyParams,
      child: (<SP extends string>(
        path: SP,
        Component?: ComponentImpl<`${PP}${P}${SP}`>,
        {
          parent,
          components,
        }: { parent: boolean; components?: ComponentMap<`${PP}${P}${SP}`> } = {
          parent: false,
        }
      ) => {
        return _typedRoute<SP, `${PP}${P}`>(
          path,
          {
            ...components,
            ...(Component && { default: Component }),
          },
          route,
          true,
          parent
        );
      }) as AnyParams,
    }
  );
  if (isChildren) {
    base?.children.push(route);
  } else {
    base?.dependencies.push(route);
  }
  return route;
}

export function root() {
  return _typedRoute<"", "">("");
}

const collectRoutes = (
  routes: TypedRoute<string>[]
): TypedRoute<string, AnyParams>[] => {
  return routes.concat(
    ...routes.map((route) => collectRoutes(route.dependencies))
  );
};

const collectAllRoutes = (
  routes: TypedRoute<string, AnyParams>[]
): TypedRoute<string, AnyParams>[] => {
  return routes.concat(
    ...routes.map((route) => collectAllRoutes(route.dependencies)),
    ...routes.map((route) => collectAllRoutes(route.children))
  );
};

const _untypeRoutes = (
  routeMap: TypedRoute<string, AnyParams>[],
  namedRoute = false
): Record<string, RouteObject[]> => {
  const map = {} as Record<
    string,
    Record<string, TypedRoute<string, AnyParams>>
  >;
  for (const route of namedRoute
    ? collectAllRoutes(routeMap)
    : collectRoutes(routeMap)) {
    const { realPath, componentMap, path } = route;
    if (namedRoute && path === "") {
      continue;
    }
    for (const [key, component] of componentMap
      ? Object.entries(componentMap)
      : []) {
      map[key] = map[key] || {};
      if (map[key][realPath] && map[key][realPath] !== route) {
        if (map[key][realPath].componentMap?.[key] === component) {
          continue;
        }
        console.error("duplicate route", namedRoute, {
          old: map[key][realPath],
          new: route,
          key,
          realPath,
        });
      }
      map[key][realPath] = route;
    }
  }
  const named = {} as Record<string, RouteObject[]>;
  for (const name of Object.keys(map)) {
    named[name] = sortRoute(Object.keys(map[name])).map((realPath: string) => {
      const { realPath: path, componentMap, children } = map[name][realPath];
      const Component = wrapComponent(
        componentMap?.[name] as ComponentImpl<string>
      );
      const named = namedRoute
        ? undefined
        : children && _untypeRoutes(children);

      return {
        path,
        ...(named?.default && { children: named?.default }),
        element: <Component />,
      };
    });
  }
  return named;
};

const filterRoot = (
  routeMap: Record<string, TypedRoute<string, AnyParams>>
): TypedRoute<string, AnyParams>[] => {
  const routes = new Set(Object.values(routeMap));
  const traverseChildren = (route: TypedRoute<string, AnyParams>) => {
    for (const child of route.children) {
      routes.delete(child);
      traverseChildren(child);
    }
    for (const child of route.dependencies) {
      routes.delete(child);
      traverseChildren(child);
    }
  };
  for (const route of Array.from(routes)) {
    traverseChildren(route);
  }
  return Array.from(routes);
};

const untypeRoutes = (
  routeMap: Record<string, TypedRoute<string, AnyParams>>,
  namedRoute = false
): Record<string, RouteObject[]> => {
  // For debug
  for (const [key, value] of Object.entries(routeMap)) {
    (value as AnyParams).id = key;
  }
  const routes = filterRoot(routeMap);
  return _untypeRoutes(routes, namedRoute);
};

//
type CheckEmptyParams<
  T extends keyof M,
  M extends Record<string, TypedRoute<string, AnyParams>>
> = Record<string, never> extends paramsOfRoute<M[T]> ? T : never;

export const createResolver = <
  M extends Record<string, TypedRoute<string, AnyParams>>
>(
  routeMap: M
) => {
  function resolveRoute<T extends keyof M>(
    id: T & CheckEmptyParams<T, M>
  ): string;
  function resolveRoute<T extends keyof M>(
    id: T,
    params: paramsOfRoute<M[T]>
  ): string;
  function resolveRoute<T extends keyof M>(
    id: T,
    params?: paramsOfRoute<M[T]>
  ) {
    const route: M[T] = routeMap[id];
    return route(params);
  }

  return resolveRoute;
};

export const createRoutes = <
  M extends Record<string, TypedRoute<string, AnyParams>>
>(
  routeMap: M,
  name = "default"
) => {
  const untypedRoutes = untypeRoutes(routeMap);
  // console.log("Routes created", routeMap, untypedRoutes);
  return () => useRoutes(untypedRoutes[name]);
};

export const createNavigateHook = <
  M extends Record<string, TypedRoute<string, AnyParams>>
>(
  routeMap: M
) => {
  const resolver = createResolver(routeMap);
  return () => {
    const originalNavigate = useNavigate();

    function navigate<T extends keyof M>(id: T & CheckEmptyParams<T, M>): void;
    function navigate<T extends keyof M>(
      id: T,
      params: paramsOfRoute<M[T]>
    ): void;
    function navigate<T extends keyof M>(id: T, params?: paramsOfRoute<M[T]>) {
      originalNavigate(resolver(id, (params || {}) as paramsOfRoute<M[T]>));
    }

    return navigate;
  };
};

export const createNamedOutletHook = <
  M extends Record<string, TypedRoute<string, AnyParams>>
>(
  routeMap: M
) => {
  const untypedRoutes = untypeRoutes(routeMap, true);
  // console.log("Named routes created", untypedRoutes, routeMap);
  return (name: string) => {
    const { matches } = useContext(UNSAFE_RouteContext);
    const match = matches[matches.length - 1];
    const { route } = match;
    if (!route) {
      return function DammyOutlet() {
        return <></>;
      };
    }
    const routes: RouteObject[] | undefined = untypedRoutes[name];
    if (!routes) {
      console.error("route has no named routes", route);
    }
    const filiteredRoutes = (routes || [])
      .map((item) => {
        if (item.path?.startsWith(route.path || "")) {
          return {
            ...item,
            path: item.path?.slice(route.path?.length),
          };
        } else {
          return null;
        }
      })
      .filter((item) => item) as RouteObject[];
    return () => useRoutes(filiteredRoutes);
  };
};
