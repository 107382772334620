import React, { useMemo } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";

import "./selector.scss";
import { DisplayComponent } from "./common/displayWrapper";
import firebaseApp from "firebaseApp";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getFirestore,
  query,
} from "firebase/firestore";
import { useDocumentData, useQueryDataWithId } from "models/hook";
import { filterParams, getQueryConstraints } from "utils/queryUtil";

interface MultipleExternalKeySchema extends BaseSchema {
  schemaType: "multipleExternalKey";
  uiType?: "vertial" | "horizontal";
  resourcePath: string;
  titleKey: string;
  filter?: filterParams;
}

const firestore = getFirestore(firebaseApp);

const MultipleExternalKeySchemaComponent: SchemaComponent<
  MultipleExternalKeySchema
> = (props: SchemaComponentInternalProps<MultipleExternalKeySchema>) => {
  const { schema } = props;
  const { resourcePath, titleKey, filter, uiType } = schema;
  const { list: options } = useQueryDataWithId(
    query(
      collection(firestore, resourcePath),
      ...getQueryConstraints({ filter })
    ) as CollectionReference<{
      id: string;
      [key: string]: string;
    }>
  );
  const {
    registerProps: { onChange },
    value,
    fieldState,
  } = useField(props, [requiredValidator]);
  const valueSet = useMemo(() => new Set(value), [value]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <div
        style={{ margin: "5px 0" }}
        className={classNames(
          {
            "has-error": fieldState.invalid,
          },
          uiType && `selector-${uiType}`
        )}
      >
        {options?.map((item, index) => {
          const { [titleKey]: title, id: optionValue } = item;
          return (
            <span
              key={index}
              className={classNames(
                "selectorOption",
                valueSet.has(optionValue) && "active"
              )}
              onClick={(e) => {
                if (valueSet.has(optionValue)) {
                  valueSet.delete(optionValue);
                } else {
                  valueSet.add(optionValue);
                }
                onChange(Array.from(valueSet));
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              {title}
            </span>
          );
        })}
      </div>
    </FieldWrapper>
  );
};

MultipleExternalKeySchemaComponent.display = DisplayComponent(
  "MultipleExternalKeyDisplayComponent",
  (value, schema) => {
    const { resourcePath, titleKey, filter } = schema;
    const { list: options } = useQueryDataWithId(
      query(
        collection(firestore, resourcePath),
        ...getQueryConstraints({ filter })
      ) as CollectionReference<{
        id: string;
        [key: string]: string;
      }>
    );
    const valueSet = useMemo(() => {
      try {
        return new Set(Array.isArray(value) ? value : [value]);
      } catch (e) {
        return new Set();
      }
    }, [value]);
    return (
      <div>
        {options
          ?.filter((option) => valueSet.has(option.id))
          .map((option, index, array) => {
            return (
              <span key={index}>
                <span key="Index">{option[titleKey]}</span>
                {index < array.length - 1 ? <span>, </span> : ""}
              </span>
            );
          })}
      </div>
    );
  }
);

export default MultipleExternalKeySchemaComponent;
