import React from "react";
import getSchema from "./schema";
import SchemaListPage from "components/SchemaListPage";
import { useApiAccountList } from "models/apiAccount";

import { APIACCOUNTS } from "../route";
import { resolveRoute } from "pages";
import { useAdminAuthentication } from "hooks/auth";

export const LIST_APIACCOUNTS = APIACCOUNTS.sub("", ApiAccounts);

function ApiAccounts({ campaignId }: { campaignId: string }) {
  const { list, loading, error } = useApiAccountList({ campaignId });
  const { user } = useAdminAuthentication();
  const userId = user?.uid;
  console.log("error", error);
  const parameter = {
    title: "アカウント一覧",
    edit: {
      path: (apiAccountId: string) =>
        resolveRoute("UPDATE_APIACCOUNT", { apiAccountId, campaignId }),
    },
    create: userId
      ? {
          path: () =>
            resolveRoute("UPDATE_APIACCOUNT", {
              campaignId,
              apiAccountId: userId,
            }),
        }
      : undefined,
    schema: getSchema({ campaignId }),
  };
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
    ></SchemaListPage>
  );
}

export default ApiAccounts;
