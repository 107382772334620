/* eslint-disable camelcase */
import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useCustomTagDoc } from "models/customTag";
import { CUSTOMTAG, CUSTOMTAGS } from "../route";
import { resolveRoute } from "pages";
import { customTagModel } from "models/data";
import { InstagramApi } from "pages/admin/profile/instagramApi";
import { adminOwnerCollection } from "models/adminOwner";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export const UPDATE_CUSTOMTAG = CUSTOMTAG.sub("", CustomTag);
export const ADD_CUSTOMTAG = CUSTOMTAGS.sub("/new", CustomTag);

function CustomTag({
  customTagId,
  campaignId,
}: {
  customTagId?: string;
  campaignId: string;
}) {
  const isEditing = !!customTagId;
  const { data, loading, ref } = useCustomTagDoc({ customTagId, campaignId });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "カスタムタグ編集" : "カスタムタグ新規作成",
    edit: {
      title: `${isEditing ? "編集" : "作成"}`,
      confirm: `${isEditing ? "編集" : "作成"}しますか？`,
      handler: ref.set,
    },
    remove: isEditing ? { handler: ref.delete } : undefined,
    schema: getSchema({ isEditing }),
    backPath: resolveRoute("LIST_CUSTOMTAGS", { campaignId }),
  };
  return (
    <SchemaFormPage
      data={isEditing ? data : {}}
      parameter={parameter}
      loading={isEditing && loading}
    />
  );
}

export default CustomTag;
