import {
  getFirestore,
  collection,
  CollectionReference,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { adminUserData } from "./data";
import { createDocumentHook, createQueryHook } from "./hook";
import { mediaItem } from "./mediaItem";

const firestore = getFirestore(firebaseApp);

export const adminUserCollection = collection(
  firestore,
  "adminUser"
) as CollectionReference<adminUserData>;

export const useAdminUserDoc = createDocumentHook(
  (adminUserId?: string) => [
    adminUserId
      ? doc(adminUserCollection, adminUserId)
      : doc(adminUserCollection),
  ],
  "id"
);

export const useAdminUserList = createQueryHook(
  () => {
    return [
      query(adminUserCollection, orderBy("createdAt", "desc")),
      adminUserCollection,
    ];
  },
  "id",
  "createdAt"
);
