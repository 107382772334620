import React from "react";

import { Control, useWatch } from "react-hook-form";

import { useGetAdminUserRecord } from "functions";
import userSchema from "./userSchema";
import { DynamicField } from "components/DynamicField";
import { Loading } from "components/Loading";

export const PreviewComponent = ({ control }: { control: Control }) => {
  const { id: adminUserId } = useWatch({ control });
  const [userRecord, userRecordLoading] = useGetAdminUserRecord(
    adminUserId ? { uid: adminUserId } : undefined
  );
  return userRecordLoading ? (
    <Loading />
  ) : userRecord ? (
    <DynamicField data={userRecord} schema={userSchema} />
  ) : (
    <div></div>
  );
};
