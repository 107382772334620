import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Value } from "react-hook-schema-form";
import { Field, Schema } from "schemaComponents";

export const SchemaInput = ({
  data,
  readOnly,
  schema,
  onValueChange,
}: {
  data: Value;
  schema: Schema;
  readOnly?: boolean;

  onValueChange?: (value: Value) => void;
}) => {
  if (schema.schemaType === "object") {
    const { control } = useForm({
      defaultValues: data,
    });
    const value = useWatch({ control });
    if (onValueChange) {
      useEffect(() => {
        if (data !== value) {
          onValueChange?.(value);
        }
      }, [value]);
    }
    return <Field control={control} schema={schema} readOnly={readOnly} />;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { control } = useForm<any>({
      defaultValues: { default: data },
    });
    const value = useWatch({ control });
    if (onValueChange) {
      useEffect(() => {
        if (data !== value.default) {
          onValueChange?.(value.default);
        }
      }, [value.default]);
    }
    return (
      <Field
        control={control}
        schema={
          {
            schemaType: "object",
            properties: [
              {
                title: "default",
                propertyName: "default",
                noLabel: true,
                schema,
              },
            ],
          } as Schema
        }
        readOnly={readOnly}
      />
    );
  }
};
