/* eslint-disable camelcase */
import {
  getAuth,
  linkWithPopup,
  FacebookAuthProvider,
  reauthenticateWithPopup,
  unlink as unlinkFirebase,
} from "firebase/auth";
import { useAdminOwnerDoc } from "models/adminOwner";
import { useEffect } from "react";
import { useAdminAuthentication } from "hooks/auth";
import { UserApi } from "./instagramApi";
import { deleteField, FieldPath } from "firebase/firestore";

const provider = new FacebookAuthProvider();
provider.addScope("pages_show_list");
provider.addScope("instagram_basic");

const auth = getAuth();

export const link = async () => {
  if (!auth.currentUser) {
    console.error("not logged in");
    return;
  }
  const alreadyLinked = auth.currentUser.providerData.some(
    (provider) => provider.providerId === "facebook.com"
  );
  // const alreadyLinked = false;
  try {
    const result = await (alreadyLinked
      ? reauthenticateWithPopup
      : linkWithPopup)(auth.currentUser, provider);
    const credential = FacebookAuthProvider.credentialFromResult(result);

    return credential;
  } catch (e) {
    console.log(e);
  }
};

export const unlink = async () => {
  if (!auth.currentUser) {
    console.error("not logged in");
    return;
  }
  try {
    await unlinkFirebase(auth.currentUser, "facebook.com");
  } catch (e) {
    console.error(e);
  }
};

export const useFacebookAccount = () => {
  const { user } = useAdminAuthentication();
  const { data, loading, ref } = useAdminOwnerDoc(user?.uid);

  const onLink = async () => {
    const credential = await link();
    const access_token = credential?.accessToken;
    if (!access_token) {
      return;
    }
    const userApi = new UserApi({ access_token });
    const response = await userApi.myAccounts();
    console.log({ response });
    const { data } = response;
    const instagramUsers = data
      .filter((page) => page.instagram_business_account?.id)
      .map((page) => {
        return {
          title: `${page.name}(${page.category})`,
          value: page.instagram_business_account.id,
        };
      });
    await ref.update({
      facebook: {
        credential: { accessToken: access_token },
        instagramUsers,
        updatedAt: Date.now(),
      },
    });
  };
  const onUnlink = async () => {
    await unlink();
    await ref.update({
      facebook: {
        credential: deleteField() as any,
        instagramUsers: deleteField() as any,
        updatedAt: Date.now(),
      },
    });
  };
  const force = false;
  const actionRequired =
    user &&
    !loading &&
    (force || Date.now() - (data?.facebook?.updatedAt || 0) > 24 * 3600 * 1000);
  useEffect(() => {
    console.log({ actionRequired });
    if (actionRequired) {
      if (data?.linkToFacebookAccount) {
        onLink();
      } else {
        onUnlink();
      }
    }
  }, [actionRequired, user]);
};
