import { createIntl, createIntlCache } from "react-intl";

import english from "languages/en.json";
import spanish from "languages/es.json";
import japanese from "languages/ja.json";

export const messages = {
  en: english,
  es: spanish,
  ja: japanese,
};

const cache = createIntlCache();

export type Locale = keyof typeof messages;

const getIntlContext = (locale: Locale) => {
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const availableLocales = Object.keys(messages) as Locale[];

export const browserLocale = navigator.language.split(/[-_]/)[0];
