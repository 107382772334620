import dayjs from "dayjs";
import { ObjectSchema, PropertyType } from "schemaComponents";

const quoteString = (str: string) => {
  return `"${str.replace(/"/g, '""')}"`;
};

const stringifyForCSV = (item: unknown) => {
  if (typeof item === "string") {
    return quoteString(item);
  } else if (typeof item === "number" || typeof item === "boolean") {
    return item;
  } else if (Array.isArray(item)) {
    if (item.every((v) => typeof v === "string" || typeof v === "number")) {
      return quoteString(item.join(","));
    } else {
      return quoteString(JSON.stringify(item));
    }
  } else if (item) {
    return quoteString(JSON.stringify(item));
  } else {
    return "";
  }
};

const formatDateForCSV = (value: unknown, format: string) => {
  return typeof value === "number" ? dayjs(value).format(format) : "";
};

const iterateField = function* (
  schema: ObjectSchema,
  accessor: (obj: unknown) => unknown,
  list: Record<string, unknown>[]
): Generator<
  [PropertyType | { propertyName: string }, (obj: unknown) => unknown]
> {
  // eslint-disable-next-line no-constant-condition
  if (schema.outputOptions?.useRealFields) {
    const fields = new Set<string>();
    for (const item of list) {
      for (const key of Object.keys(item)) {
        fields.add(key);
      }
    }
    for (const propertyName of Array.from(fields).sort()) {
      const propertyAccessor = (obj: unknown) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accessor(obj) as any)?.[propertyName];
      yield [{ propertyName }, propertyAccessor];
    }
  } else {
    for (const property of schema.properties) {
      const { propertyName } = property;
      if (!propertyName) {
        continue;
      }
      const propertyAccessor = (obj: unknown) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (accessor(obj) as any)?.[propertyName];
      if (property.schema.schemaType === "object") {
        yield* iterateField(
          property.schema,
          propertyAccessor,
          list.map(propertyAccessor)
        );
      } else {
        yield [property, propertyAccessor];
      }
    }
  }
};

export const arrayToCsv = (
  list: Record<string, unknown>[],
  schema: ObjectSchema
) => {
  const fields = Array.from(iterateField(schema, (v) => v, list));
  let output =
    fields.map(([{ propertyName }]) => propertyName).join(",") + "\n";
  for (const item of list) {
    output +=
      fields
        .map(([property, accessor]) => {
          const schema = "schema" in property ? property.schema : undefined;
          const value = accessor(item);
          if (schema?.schemaType === "datetime") {
            return formatDateForCSV(value, "YYYY/MM/DD HH:mm:ss");
          } else if (schema?.schemaType === "date") {
            return formatDateForCSV(value, "YYYY/MM/DD");
          } else if (schema?.schemaType === "boolean") {
            return value ? "true" : "";
          } else {
            return stringifyForCSV(value);
          }
        })
        .join(",") + "\n";
  }
  return output;
};
