import React from "react";
import { Schema } from "schemaComponents";
import { couponUrl } from "utils/coupon";

export default ({ isEditing }: { isEditing: boolean }): Schema => {
  return {
    schemaType: "object",
    properties: [
      {
        title: "ハッシュタグId",
        propertyName: "hashTagId",
        schema: {
          schemaType: "text",
          readOnly: true,
          required: true,
        },
      },
      {
        title: "ハッシュタグ名",
        propertyName: "hashTagName",
        schema: {
          schemaType: "text",
          readOnly: isEditing,
          required: true,
        },
      },
      {
        title: "最大取得ページ数",
        propertyName: "limit",
        schema: {
          schemaType: "number",
        },
      },
    ],
  };
};
