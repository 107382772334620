import React from "react";

import { Link } from "react-router-dom";
import NavLink from "components/Navigation/Link";
import { useAdminAuthentication } from "hooks/auth";
import { useCampaignDoc } from "models/campaign";
import { resolveRoute } from "pages";
import { useHashTagDoc } from "models/hashTag";

export const HashTagMenu = ({
  campaignId,
  hashTagId,
}: {
  campaignId: string;
  hashTagId: string;
}) => {
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  const { data } = useHashTagDoc({ campaignId, hashTagId });
  if (!campaignId) return <div>Internal error</div>;
  return (
    <>
      <Link
        to={resolveRoute("UPDATE_HASHTAG", { campaignId, hashTagId })}
        className="aside-tools"
      >
        <div className="aside-tools-label">{data?.hashTagName}</div>
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          <li>
            <NavLink to={resolveRoute("LIST_HASHTAGS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-backburger" />
              </span>
              <span className="menu-item-label">戻る</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={resolveRoute("UPDATE_HASHTAG", { campaignId, hashTagId })}
            >
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">ハッシュタグ編集</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={resolveRoute("LIST_APILOGS", { campaignId, hashTagId })}
            >
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">ログ一覧</span>
            </NavLink>
          </li>
          {/* 
          <li>
            <NavLink to={resolveRoute("LIST_COUPONS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">クーポン一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_ENQUETES", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">アンケート一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_SURVEYS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">調査一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_REWARDS", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">ハッシュタグ一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("LIST_MAIL_TEMPLATES", { campaignId })}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">メールテンプレート</span>
            </NavLink>
          </li> */}
        </ul>
      </div>
    </>
  );
};
