/* eslint-disable camelcase */
import firebaseApp from "../firebaseApp";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserInfo } from "firebase/auth";
import { useMemo, useState } from "react";

const functions = getFunctions(firebaseApp);

// const extractData = <T, R>(handler: (params: T) => Promise<{ data: R }>) => {
//   return async (params: T) => (await handler(params)).data;
// };

// type LongTimeToken = {
//   access_token: string;
//   expires_in: string;
// };

// export const getAccessToken = extractData(
//   httpsCallable<{ access_token: string }, LongTimeToken>(
//     getFunctions(firebaseApp, "asia-northeast1"),
//     "getAccessToken"
//   )
// );

export const getAdminUserCallable = httpsCallable(functions, "getAdminUser");
export const getAdminUser = async (
  params?: { uid: string } | { email: string } | undefined
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response: any = await getAdminUserCallable(params);
  return response.data as AdminUserResponse;
};

type AdminUserResponse = {
  user: UserInfo | undefined;
  uid: string | undefined;
};

export const useGetAdminUserRecord = (
  params?: { uid: string } | { email: string } | undefined
) => {
  const [response, setResponse] = useState<AdminUserResponse | undefined>(
    undefined
  );
  useMemo(async () => {
    if (!params) {
      setResponse(undefined);
    } else {
      const response = await getAdminUser(params);
      setResponse(response);
    }
  }, [JSON.stringify(params)]);
  return [response?.user, params && !response] as [
    UserInfo | undefined,
    boolean
  ];
};
