import { ObjectSchema } from "schemaComponents";

const getSchema = ({ campaignId }: { campaignId: string }): ObjectSchema => ({
  schemaType: "object",
  properties: [
    {
      title: "ユーザId",
      propertyName: "userId",
      schema: {
        schemaType: "externalKey",
        titleKey: "name",
        resourcePath: `/adminUser`,
      },
    },
    {
      title: "1時間あたりの最大アクセス数",
      propertyName: "limit",
      schema: {
        schemaType: "number",
        required: true,
      },
    },
  ],
});
export default getSchema;
