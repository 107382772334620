import React from "react";
import NavLink from "components/Navigation/Link";
import { resolveRoute } from "pages";
import { useAdminAuthentication } from "hooks/auth";
import { Link } from "react-router-dom";

export const AdminMenu = () => {
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";
  return (
    <>
      <Link to={resolveRoute("ADMIN_HOME")} className="aside-tools">
        <div className="aside-tools-label">
          <span>ファンくるキャンペーン</span>
        </div>
      </Link>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          <li>
            <NavLink to={resolveRoute("LIST_CAMPAIGNS")}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">キャンペーン一覧</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={resolveRoute("PROFILE")}>
              <span className="icon">
                <i className="mdi mdi-account-supervisor" />
              </span>
              <span className="menu-item-label">プロフィール</span>
            </NavLink>
          </li>

          {isMaster && (
            <li>
              <NavLink to={resolveRoute("LIST_ADMIN_USER")}>
                <span className="icon">
                  <i className="mdi mdi-account-supervisor" />
                </span>
                <span className="menu-item-label">管理ユーザ一覧</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};
