import { Schema } from "schemaComponents";

const schema: Schema = {
  schemaType: "object",
  properties: [
    {
      propertyName: "email",
      title: "メールアドレス",
      schema: {
        schemaType: "email",
        required: true,
      },
    },
  ],
};

export default schema;
