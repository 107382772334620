import {
  getFirestore,
  collection,
  CollectionReference,
  doc,
  query,
  orderBy,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { adminOwnerData } from "./data";
import { createDocumentHook, createQueryHook } from "./hook";

const firestore = getFirestore(firebaseApp);

export const adminOwnerCollection = collection(
  firestore,
  "adminOwner"
) as CollectionReference<adminOwnerData>;

export const useAdminOwnerDoc = createDocumentHook(
  (adminOwnerId?: string) => [
    adminOwnerId
      ? doc(adminOwnerCollection, adminOwnerId)
      : doc(adminOwnerCollection),
  ],
  "userId"
);

export const useAdminOwnerList = createQueryHook(
  () => {
    return [
      query(adminOwnerCollection, orderBy("createdAt", "desc")),
      adminOwnerCollection,
    ];
  },
  "userId",
  "createdAt"
);
