import React from "react";
import schema from "./schema";
import SchemaListPage from "components/SchemaListPage";
import { useCustomTagList } from "models/customTag";

import { CUSTOMTAGS } from "../route";
import { resolveRoute } from "pages";

export const LIST_CUSTOMTAGS = CUSTOMTAGS.sub("", CustomTags);

function CustomTags({ campaignId }: { campaignId: string }) {
  const { list, loading, error } = useCustomTagList({ campaignId });
  console.log("error", error);
  const parameter = {
    title: "カスタムタグ一覧",
    edit: {
      path: (customTagId: string) =>
        resolveRoute("UPDATE_CUSTOMTAG", { customTagId, campaignId }),
    },
    create: { path: () => resolveRoute("ADD_CUSTOMTAG", { campaignId }) },
    schema,
  };
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
    ></SchemaListPage>
  );
}

export default CustomTags;
