import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { CAMPAIGN, CAMPAIGNS } from "../route";
import { resolveRoute } from "pages";
import { useCampaignDoc } from "models/campaign";

export const UPDATE_CAMPAIGN = CAMPAIGN.sub("/edit", Campaign);
export const ADD_CAMPAIGN = CAMPAIGNS.sub("/edit/new", Campaign);

function Campaign({ campaignId }: { campaignId?: string }) {
  const isEditing = !!campaignId;
  const { data, loading, ref } = useCampaignDoc(campaignId);
  const parameter: SchemaFormParameter = {
    title: isEditing ? "キャンペーン編集" : "キャンペーン新規作成",
    schema: getSchema({ isEditing }),
    edit: {
      title: `${isEditing ? "編集" : "作成"}`,
      confirm: `${isEditing ? "編集" : "作成"}しますか？`,
      handler: ref.set,
    },
    remove: { handler: ref.delete },
    backPath: resolveRoute("LIST_CAMPAIGNS"),
  };
  return (
    <SchemaFormPage
      data={isEditing ? data : {}}
      parameter={parameter}
      loading={isEditing && loading}
    />
  );
}

export default Campaign;
