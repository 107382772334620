import {
  getFirestore,
  collection,
  CollectionReference,
  setDoc,
  doc,
} from "firebase/firestore";
import firebaseApp from "firebaseApp";
import { mediaItem } from "./mediaItem";
import { createQueryHook, createDocumentHook } from "../utils/firestore";
import { apiLogModel, apiLogPageModel } from "./data";

const firestore = getFirestore(firebaseApp);

export const apiLogCollection = ({
  campaignId,
  hashTagId,
}: {
  campaignId: string;
  hashTagId: string;
}) => {
  return collection(
    firestore,
    `/campaign/${campaignId}/hashTag/${hashTagId}/apiLog`
  ) as CollectionReference<apiLogModel>;
};

export const apiLogPageCollection = ({
  campaignId,
  apiLogId,
  hashTagId,
}: {
  campaignId: string;
  apiLogId: string;
  hashTagId: string;
}) => {
  return collection(
    firestore,
    `/campaign/${campaignId}/hashTag/${hashTagId}/apiLog/${apiLogId}/page`
  ) as CollectionReference<apiLogPageModel>;
};

export const addApiLog = async (
  {
    campaignId,
    hashTagId,
  }: {
    campaignId: string;
    hashTagId: string;
  },
  data: null
) => {
  const apiLogId = `${Date.now()}`;
  const docRef = doc(apiLogCollection({ campaignId, hashTagId }), apiLogId);
  await setDoc(docRef, { createdAt: Date.now() });
  return apiLogId;
};

// export const addApiLogPage = async (
//   {
//     campaignId,
//     apiLogId,
//     hashTagId,
//     page,
//   }: {
//     campaignId: string;
//     apiLogId: string;
//     hashTagId: string;
//     page: number;
//   },
//   data: mediaItem[]
// ) => {
//   const docRef = doc(
//     apiLogPageCollection({ campaignId, apiLogId, hashTagId }),
//     `${page}`
//   );
//   await setDoc(docRef, { data, count: 1 });
// };

export const useApiLogPage = createQueryHook(
  ({
    campaignId,
    apiLogId,
    hashTagId,
  }: {
    campaignId: string;
    apiLogId: string;
    hashTagId: string;
  }) => {
    const collection = apiLogPageCollection({
      campaignId,
      apiLogId,
      hashTagId,
    });
    return [collection, collection];
  },
  "id",
  "createdAt"
);

export const useApiLogDoc = createDocumentHook(
  ({
    campaignId,
    hashTagId,
    apiLogId,
  }: {
    campaignId: string;
    hashTagId: string;
    apiLogId?: string;
  }) => {
    const collection = apiLogCollection({ campaignId, hashTagId });
    return [apiLogId ? doc(collection, apiLogId) : doc(collection)];
  },
  "hashTagId"
);

export const useApiLogList = createQueryHook(
  ({ campaignId, hashTagId }: { campaignId: string; hashTagId: string }) => {
    const collection = apiLogCollection({
      campaignId,
      hashTagId,
    });
    return [collection, collection];
  },
  "id",
  "createdAt"
);
