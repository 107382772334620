import React from "react";
import schema from "./schema";
import SchemaListPage from "components/SchemaListPage";
import { useHashTagList } from "models/hashTag";

import { HASHTAGS } from "../route";
import { resolveRoute } from "pages";

export const LIST_HASHTAGS = HASHTAGS.sub("", HashTags);

function HashTags({ campaignId }: { campaignId: string }) {
  const { list, loading, error } = useHashTagList({ campaignId });
  console.log("error", error);
  const parameter = {
    title: "ハッシュタグ一覧",
    edit: {
      path: (hashTagId: string) =>
        resolveRoute("UPDATE_HASHTAG", { hashTagId, campaignId }),
    },
    create: { path: () => resolveRoute("ADD_HASHTAG", { campaignId }) },
    schema,
  };
  return (
    <SchemaListPage
      parameter={parameter}
      list={list}
      loading={loading}
    ></SchemaListPage>
  );
}

export default HashTags;
