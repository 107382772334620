import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";

import "./numberSelector.scss";
import { DisplayComponent } from "./common/displayWrapper";

interface NumberSelectorSchema extends BaseSchema {
  schemaType: "numberSelector";
  textBefore?: string;
  textAfter?: string;
  minValue: number;
  maxValue: number;
}

const NumberSelectorSchemaComponent: SchemaComponent<NumberSelectorSchema> = (
  props: SchemaComponentInternalProps<NumberSelectorSchema>
) => {
  const { readOnly, schema } = props;
  const { textBefore, textAfter, maxValue, minValue } = schema;
  const {
    registerProps: { onChange },
    value,
    fieldState,
  } = useField(props, [requiredValidator]);

  const list = [...Array(maxValue - minValue + 1)].map((_, i) => i + minValue);

  return (
    <FieldWrapper fieldState={fieldState}>
      <div className="selector-horizontal">
        <div
          style={{
            display: "table",
          }}
          className={classNames({
            "has-error": fieldState.invalid,
          })}
        >
          {textBefore && (
            <div className="text" style={{ textAlign: "left" }}>
              {textBefore}
            </div>
          )}
          <div>
            {list.map((optionValue) => (
              <span
                key={optionValue}
                className={classNames(
                  "numberSelectorOption",
                  value != null && optionValue <= value && "active"
                )}
                onClick={(e) => {
                  onChange(optionValue !== value ? optionValue : null);
                }}
                onMouseDown={(e) => e.preventDefault()}
              >
                {optionValue}
              </span>
            ))}
          </div>
          {textAfter && (
            <div className="text" style={{ textAlign: "right" }}>
              {textAfter}
            </div>
          )}
        </div>
      </div>
    </FieldWrapper>
  );
};

NumberSelectorSchemaComponent.display = DisplayComponent(
  "NumberSelectorDisplayComponent"
);

export default NumberSelectorSchemaComponent;
