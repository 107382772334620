import React from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
  useValue,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";

interface UrlSchema extends BaseSchema {
  schemaType: "url";
  required?: boolean;
}

const UrlSchemaComponent: SchemaComponent<UrlSchema> = (props) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <Form.Control
        type="url"
        className={classNames({
          "has-error": fieldState.invalid,
        })}
        value={value || ""}
        {...registerProps}
      />
    </FieldWrapper>
  );
};

UrlSchemaComponent.display = DisplayComponent(
  "UrlDisplayComponent",
  (value) => {
    return value ? (
      <a
        style={{ wordBreak: "break-all" }}
        href={value}
        target="_blank"
        rel="noreferrer"
      >
        {value}
      </a>
    ) : (
      ""
    );
  }
);

export default UrlSchemaComponent;
