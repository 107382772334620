/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useFacebookAccount } from "./link";
import { useAdminOwnerDoc } from "models/adminOwner";
import { useAdminAuthentication } from "hooks/auth";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";

import getSchema from "./schema";
import { useWatch } from "react-hook-form";
import { ADMIN as BASE } from "..";
import { useToost } from "hooks/dialogs";
export const PROFILE = BASE.child("/profile", Home);

function Home() {
  const { user } = useAdminAuthentication();
  const { data, loading, ref } = useAdminOwnerDoc(user?.uid);
  const [linkToFacebookAccount, setLinkToFacebookAccount] = useState<boolean>();
  const isEditing = true;
  const parameter: SchemaFormParameter = {
    title: "プロフィール編集",
    schema: getSchema({
      isEditing,
      instagramUsers: data?.facebook?.instagramUsers,
      linkToFacebookAccount,
    }),
    edit: {
      title: `${isEditing ? "編集" : "作成"}`,
      confirm: `${isEditing ? "編集" : "作成"}しますか？`,
      handler: async (data) => {
        await ref.update(data);
        await toost({ message: "保存されました", duration: 5000 });
      },
    },
    hook: (control) => {
      const linkToFacebookAccount = useWatch({
        control,
        name: "linkToFacebookAccount",
      });
      useEffect(() => {
        setLinkToFacebookAccount(linkToFacebookAccount);
      }, [linkToFacebookAccount]);
    },
  };
  const toost = useToost();

  return (
    <>
      <SchemaFormPage
        data={data || {}}
        parameter={parameter}
        loading={loading}
      />
    </>
  );
}
export default Home;
