/* eslint-disable camelcase */
import React from "react";
import getSchema from "./schema";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useMediaDoc } from "models/media";
import { MEDIA, MEDIAS } from "../route";
import { resolveRoute } from "pages";
import { mediaModel } from "models/data";
import { InstagramApi } from "pages/admin/profile/instagramApi";
import { adminOwnerCollection } from "models/adminOwner";
import { getAuth } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

export const UPDATE_MEDIA = MEDIA.sub("", Media);
export const ADD_MEDIA = MEDIAS.sub("/new", Media);

function Media({
  mediaId,
  campaignId,
}: {
  mediaId?: string;
  campaignId: string;
}) {
  const isEditing = !!mediaId;
  const { data, loading, ref } = useMediaDoc({ mediaId, campaignId });
  const parameter: SchemaFormParameter = {
    title: isEditing ? "投稿編集" : "投稿新規作成",
    edit: !isEditing
      ? {
          title: `${isEditing ? "編集" : "作成"}`,
          confirm: `${isEditing ? "編集" : "作成"}しますか？`,
          handler: ref.set,
        }
      : undefined,
    remove: isEditing ? { handler: ref.delete } : undefined,
    schema: getSchema({ isEditing }),
    backPath: resolveRoute("LIST_MEDIAS", { campaignId }),
  };
  return (
    <SchemaFormPage
      data={isEditing ? data : {}}
      parameter={parameter}
      loading={isEditing && loading}
    />
  );
}

export default Media;
