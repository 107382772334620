import React from "react";
import {
  SchemaComponent,
  useField,
  requiredValidator,
  BaseSchema,
  useValue,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";
import { DisplayComponent } from "./common/displayWrapper";
import Form from "react-bootstrap/Form";

interface ImageSchema extends BaseSchema {
  schemaType: "image";
  required?: boolean;
}

const ImageSchemaComponent: SchemaComponent<ImageSchema> = (props) => {
  const { registerProps, value, fieldState } = useField(props, [
    requiredValidator,
  ]);
  return (
    <>
      <FieldWrapper fieldState={fieldState}>
        <Form.Control
          type="url"
          className={classNames({
            "has-error": fieldState.invalid,
          })}
          value={value || ""}
          {...registerProps}
        />
      </FieldWrapper>
      <img style={{}} src={value} />
    </>
  );
};

ImageSchemaComponent.display = DisplayComponent(
  "ImageDisplayComponent",
  (value) => {
    return value ? <img style={{ backgroundColor: "#888" }} src={value} /> : "";
  }
);

export default ImageSchemaComponent;
