import React, { useRef, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { enqueteCollection } from "models/collection";
import Spinner from "react-bootstrap/Spinner";
import SlotMachine from "components/SlotMachine";
import { useAdminAuthentication } from "hooks/auth";
import { useEnqueteDoc, useRewardDoc, useSurveyDoc } from "models/hook";

import { CONSUMER } from "..";
import ConsumerLayout from "components/ConsumerLayout";

const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export const CONSUMER_GACHA = CONSUMER.child(
  "/gacha/:enqueteId",
  ConsumerGacha
);

function ConsumerGacha({
  shopId,
  enqueteId,
}: {
  shopId?: string;
  enqueteId: string;
}) {
  const { user, loading: loadingAuthState, error } = useAdminAuthentication();
  const { data, loading: loadingDocument } = useEnqueteDoc({ enqueteId });
  const { data: rewardData, loading: loadingReward } = useRewardDoc({
    rewardId: data?.rewardId || undefined,
  });
  const { data: surveyData } = useSurveyDoc({ surveyId: data?.surveyId });

  const loading =
    loadingAuthState || loadingDocument || loadingReward || !data?.lottery;
  const [finished, setFinished] = useState(false);
  const onEnded = () => {
    setFinished(true);
    (async () => {
      if (user?.email && enqueteId) {
        await updateDoc(doc(enqueteCollection, enqueteId), {
          movieFinished: true,
        });
      }
    })();
  };
  return (
    <ConsumerLayout shopId={shopId}>
      {loading && (
        <div className={"wide-form"} style={{ padding: "30px" }}>
          <div style={{ padding: "20px" }}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        </div>
      )}
      {!loading && data?.lottery !== "duplicate" && (
        <SlotMachine animationId={data?.animationId} onFinish={onEnded} />
      )}

      {data?.lottery === "duplicate" && surveyData && (
        <div className={"wide-form"}>
          <section className="hero">
            <div className="hero-body">
              <h1 className="title">
                <div>抽選に参加いただける回数の制限を越えています。</div>
              </h1>
            </div>
          </section>
        </div>
      )}
      {finished && (
        <div className={"wide-form"}>
          <section className="hero">
            <div className="hero-body">
              <h1 className="title">
                {data?.lottery === "win" && <span>当選しました</span>}
                {data?.lottery === "lose" && <span>ハズレ</span>}
              </h1>
            </div>
          </section>
          <section className="main-section adjust-width">
            {data?.lottery === "win" && rewardData && (
              <div>
                {rewardData.rewardName}が当選しました。
                登録されたメールアドレス({user?.email}
                )にクーポンをお送りしました。
                <br />
                メールボックスを確認してください
              </div>
            )}
            {data?.lottery === "lose" && (
              <div>
                ご応募ありがとうございました。
                またのご来店をお待ちしております！
              </div>
            )}
          </section>
        </div>
      )}
    </ConsumerLayout>
  );
}
export default ConsumerGacha;
