import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "投稿ID",
      propertyName: "id",
      schema: { schemaType: "text" },
    },
    {
      title: "投稿URL",
      propertyName: "mediaUrl",
      schema: {
        schemaType: "text",
      },
    },
    {
      title: "いいね数",
      propertyName: "like_count",
      schema: {
        schemaType: "number",
      },
    },
    {
      title: "コメント数",
      propertyName: "comments_count",
      schema: {
        schemaType: "number",
      },
    },
    {
      title: "投稿日時",
      propertyName: "timestamp",
      schema: {
        schemaType: "datetime",
      },
    },
  ],
};
export default schema;
