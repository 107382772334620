import React, { useState } from "react";
import { Link } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { adminUserCollection, useAdminUserDoc } from "models/adminUser";
import { cleanObject } from "utils/firestore";
import { getAdminUser } from "functions";
import { Alert } from "react-bootstrap";
import { resolveRoute } from "pages";
import { ADMIN_USER, ADMIN_USERS } from "..";
import getSchema from "./schema";
import { PreviewComponent } from "./preview";

export const CREATE_ADMIN_USER = ADMIN_USERS.sub("/new", AdminUser);
export const UPDATE_ADMIN_USER = ADMIN_USER.sub("", AdminUser);

function AdminUser({ adminUserId }: { adminUserId?: string }) {
  const isEditing = !!adminUserId;
  const [error, setError] = useState<
    { message: string | JSX.Element } | undefined
  >();
  const { data, loading, ref } = useAdminUserDoc(adminUserId);
  const parameter: SchemaFormParameter = {
    title: isEditing ? "管理ユーザ編集" : "管理ユーザ新規作成",
    schema: getSchema({ isEditing }),
    edit: {
      title: `${isEditing ? "編集" : "作成"}`,
      confirm: `${isEditing ? "編集" : "作成"}しますか？`,
      handler: async (value) => {
        if (isEditing) {
          await ref.set(value);
        } else {
          // Check if the user exists on Firebase Authentication
          const { uid } =
            (await getAdminUser({ email: value.initialEmail })) || {};
          if (uid) {
            // Check if the user exists on Firestore
            const adminUserDoc = doc(adminUserCollection, uid);
            const snapshot = await getDoc(adminUserDoc);
            if (snapshot.exists()) {
              // emit error
              setError({
                message: (
                  <span>
                    同じメールアドレスのユーザが存在します。編集する場合は
                    <Link
                      onClick={() => setError(undefined)}
                      to={resolveRoute("UPDATE_ADMIN_USER", {
                        adminUserId: uid,
                      })}
                    >
                      こちら
                    </Link>
                    をクリックしてください
                  </span>
                ),
              });
              throw new Error(
                "An account binded to the specified email already exists"
              );
            } else {
              // save to another document
              cleanObject(value);
              await setDoc(adminUserDoc, { ...value, createdAt: Date.now() });
            }
          } else {
            // new user (both authentication and firestore)
            await ref.set(value);
          }
        }
      },
    },
    back: {},
    remove: adminUserId != null ? { handler: ref.delete } : undefined,
    preview: {
      title: "ユーザ認証情報",
      component: PreviewComponent,
    },
    backPath: resolveRoute("LIST_ADMIN_USER"),
  };
  return (
    <>
      {error && <Alert variant="danger">{error.message}</Alert>}
      <SchemaFormPage
        data={isEditing ? data : {}}
        parameter={parameter}
        loading={!!(isEditing && loading)}
      />
    </>
  );
}

export default AdminUser;
