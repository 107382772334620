import firebaseApp from "../firebaseApp";
import {
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithEmailAndPassword,
  isSignInWithEmailLink as firebaseIsSignInWithEmailLink,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
} from "firebase/auth";
import useSWR from "swr";
import { useState } from "react";
// import { registerConsumer } from "functions";
import {
  createAuthHook,
  createIdTokenHook,
} from "@smart-hook/react-smart-hook-firebase";

const auth = getAuth(firebaseApp);

class AppError extends Error {
  constructor(message: string, name?: string) {
    super(message);
    if (name) {
      this.name = name;
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyError = any;

const createSWRHook =
  <P, R>(fetcher: (arg: P) => Promise<R>) =>
  (arg: P) =>
    useSWR(arg, fetcher);

const createAsyncHook = <P, R>(fetcher: (arg: P) => Promise<R>) => {
  return () => {
    const [params, setParams] = useState(
      {} as { data?: R; error?: AnyError; running?: boolean }
    );
    const { data, error, running } = params;
    return [
      async (params: P) => {
        try {
          setParams({ running: true });
          const data = await fetcher(params);
          setParams({ data });
        } catch (e) {
          setParams({ error: e });
        }
      },
      data,
      running,
      error,
    ] as [(args: P) => Promise<void>, R | undefined, boolean, AnyError];
  };
};

const signUp = async ({
  email,
  path,
  shopId,
}: {
  email: string;
  path?: string;
  shopId: string;
}) => {
  // const searchParams = new URLSearchParams({ email });
  // const actionCodeSettings = {
  //   url: `${location.protocol}//${location.host}${
  //     path || location.pathname
  //   }?${searchParams}`,
  //   handleCodeInApp: true,
  // };
  // await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  // await registerConsumer({
  //   email,
  //   shopId,
  //   path: `${location.protocol}//${location.host}${path}`,
  // });
  return true;
};

export const useSignUp = createAsyncHook(signUp);

const signUpAdmin = async ({
  email,
  path,
}: {
  email: string;
  path?: string;
}) => {
  const searchParams = new URLSearchParams({ email });
  const actionCodeSettings = {
    url: `${location.protocol}//${location.host}${
      path || location.pathname
    }?${searchParams}`,
    handleCodeInApp: true,
  };
  await sendSignInLinkToEmail(auth, email, actionCodeSettings);
  return true;
};

export const useSignUpAdmin = createAsyncHook(signUpAdmin);

const resetPasswordAdmin = async ({
  email,
  path,
}: {
  email: string;
  path?: string;
}) => {
  const searchParams = new URLSearchParams({ email });
  const actionCodeSettings = {
    url: `${location.protocol}//${location.host}${
      path || location.pathname
    }?${searchParams}`,
    handleCodeInApp: true,
  };
  await sendPasswordResetEmail(auth, email, actionCodeSettings);
  return true;
};

export const useResetPasswordAdmin = createAsyncHook(resetPasswordAdmin);

const mailAuth = async ({ url }: { url: string }) => {
  const urlParams = new URLSearchParams(new URL(url).search);
  const email = urlParams.get("email");
  if (!email) {
    throw new AppError("email is not defined in given URL", "ParameterError");
  }
  const response = await signInWithEmailLink(auth, email, url);
  console.log("signInWithEmailLink response", response);
  return response;
};

export const useMailAuth = (params?: { url: string }) => {
  const { data, error } = useSWR(params, mailAuth);
  return {
    data,
    error,
    loading: !!params && !data && !error,
  };
};

const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response = await signInWithEmailAndPassword(auth, email, password);
  return response;
};

export const useSignIn = createAsyncHook(signIn);

export const signOut = async () => {
  console.log("start singOut");
  await firebaseSignOut(auth);
  console.log("finish signOut");
};

export const isSignInWithEmailLink = (emailLink: string) => {
  return firebaseIsSignInWithEmailLink(auth, emailLink);
};

export const changeUserPassword = async ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}) => {
  const user = auth.currentUser;
  const email = user?.email;
  if (!email || !user) {
    throw new Error();
  }
  const credential = EmailAuthProvider.credential(email, currentPassword);
  await reauthenticateWithCredential(user, credential);
  await updatePassword(user, newPassword);
};

export const useAuthentication = createAuthHook(auth, (user) => user);

const useIdTokenResult = createIdTokenHook(auth, (user, claims) => {
  console.log({ user, claims });
  return {
    providerData: user?.providerData,
    displayName: user?.displayName as string | undefined,
    photoURL: user?.photoURL as string | undefined,
    role: claims?.role as string | undefined,
    campaignIds: claims?.campaignIds as string[] | undefined,
    uid: claims?.sub as string | undefined,
    email: claims?.email as string | undefined,
  };
});

export const useAdminAuthentication = () => {
  const { data: user, error } = useIdTokenResult();
  const loading = !user && !error;
  const isGuest = user && !user.uid;
  const isAdmin = user && user.role;
  const isNotAdmin = user && user.uid && !user.role;
  return {
    isGuest,
    loading,
    error,
    isAdmin,
    isNotAdmin,
    user: user?.uid ? user : undefined,
    role: user?.role,
  };
};
