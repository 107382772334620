import React from "react";
import SchemaFormPage, { SchemaFormParameter } from "components/SchemaFormPage";
import { useSignUp } from "hooks/auth";
import { shopData } from "models/data";
import ConsumerLayout from "components/ConsumerLayout";
import { CONSUMER } from "..";
import { resolveRoute } from "pages";
import schema from "./schema";

export const CONSUMER_ENTRY = CONSUMER.child("/entry", ConsumerEntry);

function ConsumerEntry({
  shopId,
  shopData,
}: {
  shopId?: string;
  shopData?: Partial<shopData> | null;
}) {
  const [signUp, data, running, error] = useSignUp();
  const parameter: SchemaFormParameter = {
    title: "アンケートに答えて、ガチャで景品を当てよう",
    subtitle: "メールアドレスを入力してください",
    schema,
    edit: {
      title: "送信",
      handler: async (value) => {
        if (!shopId) {
          return;
        }
        await signUp({
          email: value.email,
          path: resolveRoute("CONSUMER_MAIL_AUTH", { shopId }),
          shopId,
        });
      },
      confirm: false,
    },
  };
  const completedParameter: SchemaFormParameter = {
    title: "メールアドレス確認",
    schema: {
      schemaType: "message",
      text: "メールを送信しました。メールボックスを確認してください",
    },
  };
  return (
    <ConsumerLayout shopId={shopId} shopData={shopData} skipAuthentication>
      <div className="wide-form">
        <SchemaFormPage
          data={{}}
          parameter={!data ? parameter : completedParameter}
          loading={!!running}
        />
      </div>
    </ConsumerLayout>
  );
}
export default ConsumerEntry;
