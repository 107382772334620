import React from "react";

import { useLocation, Link } from "react-router-dom";
import { useShopDoc } from "models/hook";
import { shopData } from "models/data";
import { useAdminAuthentication } from "hooks/auth";
import { Alert, Spinner } from "react-bootstrap";
import ErrorMessage from "components/ErrorMessage";
import { resolveRoute } from "pages";
import { useTracker } from "utils/analytics";

type Props = React.PropsWithChildren<{
  shopData?: Partial<shopData> | null;
  shopId?: string;
  skipAuthentication?: boolean;
}>;

const ConsumerLayout: React.FC<Props> = ({
  children,
  shopData: injectedShopData,
  shopId,
  skipAuthentication,
}: Props) => {
  useTracker();
  const { user, loading, error } = useAdminAuthentication();
  const location = useLocation();

  const { data: shopData } =
    injectedShopData !== undefined
      ? { data: injectedShopData || undefined }
      : useShopDoc({ shopId });
  if (user?.uid || skipAuthentication) {
    //
  } else if (!error && loading) {
    return (
      <div style={{ padding: "20px" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return <Timeout error={error} shopId={shopId} />;
  }

  const { logoURL: logoUrl, showShopNameInHeader } = shopData || {};
  return (
    <>
      <div
        className="consumer-container"
        style={{
          position: "absolute",
          margin: "0 auto",
          background: "#f3f3f3",
          padding: "5px 0",
          height: "50px",
          width: "100%",
        }}
      >
        <div className="adjust-width">
          {logoUrl && (
            <img
              src={logoUrl}
              style={{
                height: "40px",
                marginRight: "10px",
                verticalAlign: "middle",
              }}
            />
          )}
          {showShopNameInHeader && (
            <span
              style={{
                fontSize: "1.2rem",
                verticalAlign: "middle",
                marginLeft: 5,
                lineHeight: "40px",
              }}
            >
              {shopData?.shopName}
            </span>
          )}
        </div>
      </div>
      <div style={{ height: "50px" }}></div>
      <div className="consumer-container">{children}</div>
    </>
  );
};
export default ConsumerLayout;

const Timeout = ({ error, shopId }: { error?: Error; shopId?: string }) => {
  return (
    <Alert variant="danger">
      <div>タイムアウトしました</div>
      {error && (
        <div>
          <ErrorMessage error={error} />
        </div>
      )}
      {shopId && (
        <div>
          <Link to={resolveRoute("CONSUMER_ENTRY", { shopId })}>こちら</Link>
          からやり直してください。
        </div>
      )}
    </Alert>
  );
};
