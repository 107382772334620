import React from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";

import "./selector.scss";
import { DisplayComponent } from "./common/displayWrapper";
import firebaseApp from "firebaseApp";
import {
  collection,
  CollectionReference,
  doc,
  DocumentReference,
  getFirestore,
  query,
} from "firebase/firestore";
import { useDocumentData, useQueryDataWithId } from "models/hook";
import { filterParams, getQueryConstraints } from "utils/queryUtil";

interface SelectorSchema extends BaseSchema {
  schemaType: "externalKey";
  resourcePath: string;
  titleKey: string;
  filter?: filterParams;
}

const firestore = getFirestore(firebaseApp);

const SelectorSchemaComponent: SchemaComponent<SelectorSchema> = (
  props: SchemaComponentInternalProps<SelectorSchema>
) => {
  const { schema } = props;
  const { resourcePath, titleKey, filter } = schema;
  const { list: options } = useQueryDataWithId(
    query(
      collection(firestore, resourcePath),
      ...getQueryConstraints({ filter })
    ) as CollectionReference<{
      id: string;
      [key: string]: string;
    }>
  );
  console.log("options", options);
  const {
    registerProps: { onChange },
    value,
    fieldState,
  } = useField(props, [requiredValidator]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <div
        style={{ margin: "5px 0" }}
        className={classNames({
          "has-error": fieldState.invalid,
        })}
      >
        {options?.map((item) => {
          const { [titleKey]: title, id: optionValue } = item;
          return (
            <span
              key={optionValue}
              className={classNames(
                "selectorOption",
                optionValue === value && "active"
              )}
              onClick={(e) => {
                onChange(optionValue !== value ? optionValue : null);
              }}
              onMouseDown={(e) => e.preventDefault()}
            >
              {title}
            </span>
          );
        })}
      </div>
    </FieldWrapper>
  );
};

SelectorSchemaComponent.display = DisplayComponent(
  "SelectorDisplayComponent",
  (value, schema) => {
    const { resourcePath, titleKey } = schema;
    const { data } = useDocumentData<Record<string, string>>(
      value &&
        (doc(firestore, `${resourcePath}/${value}`) as DocumentReference<{
          id: string;
          [key: string]: string;
        }>)
    );
    if (!value) {
      return "未設定";
    }
    return data?.[titleKey] || "";
  }
);

export default SelectorSchemaComponent;
