import {
  addDoc,
  CollectionReference,
  deleteDoc,
  DocumentReference,
  Query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { FieldValues } from "react-hook-form";
import { useMemo } from "react";
import { useDocumentData, useQueryData } from "../models/hook";
import stringify from "fast-json-stable-stringify";

export const cleanObject = (value: FieldValues) => {
  if (!value || typeof value !== "object") {
    return;
  }
  Object.keys(value).forEach((key) => {
    if (value[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete value[key];
    } else if (typeof value[key] === "object") {
      cleanObject(value[key]);
    }
  });
};

const stringifyArray = (array: unknown[]) => array.map(stringify);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createDocumentHook = <T extends any[], R, K extends string>(
  documentGenerator: (...args: T) => [
    (
      | DocumentReference<R>
      | ((data: Omit<R, K> & { [P in K]?: string }) => DocumentReference<R>)
    ),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any?
  ],
  idKey: K
) => {
  return (...args: T) => {
    const { document, ref } = useMemo(() => {
      const [_document, constraint] = documentGenerator(...args);
      const document = typeof _document === "function" ? undefined : _document;
      const ref = {
        set(data: Omit<R, K> & { [P in K]?: string }) {
          cleanObject(data);
          const document =
            typeof _document === "function" ? _document(data) : _document;
          return setDoc<Omit<R, K>>(document, {
            createdAt: Date.now(),
            ...data,
            ...constraint,
          });
        },
        update(data: Omit<R, K> & { [P in K]?: string }) {
          cleanObject(data);
          const document =
            typeof _document === "function" ? _document(data) : _document;
          return setDoc<Omit<R, K>>(
            document,
            {
              createdAt: Date.now(),
              ...data,
              ...constraint,
            },
            { merge: true }
          );
        },
        async delete() {
          document && deleteDoc(document);
        },
      };
      return { document, ref };
    }, stringifyArray(args));
    const { snapshot, loading, error } = useDocumentData(document);
    const data = useMemo(
      () =>
        snapshot
          ? ({ ...snapshot.data(), [idKey]: snapshot.id } as R)
          : undefined,
      [snapshot]
    );
    const exists = snapshot?.exists();
    return {
      data,
      loading,
      error,
      ref,
      exists,
    };
  };
};

export const createQueryHook = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends any[],
  R,
  K extends string,
  C extends string
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryGenerator: (...args: T) => [Query<R>, CollectionReference<R>, any?],
  idKey: K,
  createTimeKey: C
) => {
  return (...args: T) => {
    const { query, ref } = useMemo(() => {
      const [query, collection, constraint] = queryGenerator(...args);
      const ref = {
        async add(data: Omit<R, C | K>) {
          cleanObject(data);
          const doc = await addDoc(collection, {
            ...data,
            ...constraint,
            [createTimeKey]: Date.now(),
          });
          return { id: doc.id };
        },
      };
      return { query, ref };
    }, stringifyArray(args));
    const { snapshot, loading, error } = useQueryData(query);
    const list = useMemo(
      () =>
        snapshot?.docs.map(
          (doc) =>
            ({ ...doc.data(), [idKey]: doc.id } as R & { [P in K]: string })
        ),
      [snapshot]
    );
    return {
      list,
      loading,
      error,
      ref,
    };
  };
};
