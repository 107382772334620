import { ObjectSchema } from "schemaComponents";

const schema: ObjectSchema = {
  schemaType: "object",
  properties: [
    {
      title: "カスタムタグID",
      propertyName: "id",
      schema: { schemaType: "text" },
    },
    {
      title: "カスタムタグ名",
      propertyName: "customTagName",
      schema: {
        schemaType: "text",
        required: true,
      },
    },
  ],
};
export default schema;
