import React, { useMemo } from "react";
import {
  SchemaComponent,
  SchemaComponentInternalProps,
  requiredValidator,
  BaseSchema,
  useField,
} from "react-hook-schema-form";
import classNames from "classnames";
import { FieldWrapper } from "./common/fieldWrapper";

import "./selector.scss";
import { DisplayComponent } from "./common/displayWrapper";

interface MultipleSelectorSchema extends BaseSchema {
  schemaType: "multipleSelector";
  uiType?: "vertial" | "horizontal";
  options: {
    title: string;
    value: string | number | null;
  }[];
}

const MultipleSelectorSchemaComponent: SchemaComponent<
  MultipleSelectorSchema
> = (props: SchemaComponentInternalProps<MultipleSelectorSchema>) => {
  const { readOnly, schema } = props;
  const { options, uiType } = schema;
  const {
    registerProps: { onChange },
    value,
    fieldState,
  } = useField(props, [requiredValidator]);
  const valueSet = useMemo(() => new Set(value), [value]);
  return (
    <FieldWrapper fieldState={fieldState}>
      <div
        style={{ margin: "5px 0" }}
        className={classNames(
          {
            "has-error": fieldState.invalid,
          },
          uiType && `selector-${uiType}`
        )}
      >
        {options.map(({ title, value: optionValue }, index) => (
          <span
            key={index}
            className={classNames(
              "selectorOption",
              valueSet.has(optionValue) && "active"
            )}
            onClick={(e) => {
              if (valueSet.has(optionValue)) {
                valueSet.delete(optionValue);
              } else {
                valueSet.add(optionValue);
              }
              onChange(Array.from(valueSet));
            }}
            onMouseDown={(e) => e.preventDefault()}
          >
            {title}
          </span>
        ))}
      </div>
    </FieldWrapper>
  );
};

MultipleSelectorSchemaComponent.display = DisplayComponent(
  "MultipleSelectorDisplayComponent",
  (value, schema) => {
    const { options } = schema;
    const valueSet = useMemo(() => {
      try {
        return new Set(Array.isArray(value) ? value : [value]);
      } catch (e) {
        return new Set();
      }
    }, [value]);
    return (
      <div>
        {options
          .filter((option) => valueSet.has(option.value))
          .map((option, index, array) => {
            return (
              <span key={index}>
                <span key="Index">{option.title}</span>
                {index < array.length - 1 ? <span>, </span> : ""}
              </span>
            );
          })}
      </div>
    );
  }
);

export default MultipleSelectorSchemaComponent;
