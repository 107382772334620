import React from "react";
import { doc, updateDoc } from "firebase/firestore";
import { couponCollection } from "models/collection";
import { useConfirm } from "hooks/dialogs";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import "./ConsumerCoupon.scss";
import { useFormatter } from "hooks/intl";
import { useClientDoc, useCouponDoc, useRewardDoc } from "models/hook";

import { CONSUMER } from "..";
import ConsumerLayout from "components/ConsumerLayout";

export const CONSUMER_COUPON = CONSUMER.child(
  "/coupons/:couponId",
  ConsumerCoupon
);

function ConsumerCoupon({
  shopId,
  couponId,
}: {
  shopId?: string;
  couponId: string;
}) {
  const { formatDate } = useFormatter();
  const couponDoc = couponId
    ? doc(couponCollection, couponId.toString())
    : undefined;
  const { data: couponData, loading } = useCouponDoc({ couponId });
  const { data: rewardDoc } = useRewardDoc({ rewardId: couponData?.rewardId });
  const { data: clientDoc } = useClientDoc(couponData?.clientId);

  console.log("rewardDoc", rewardDoc);

  const confirm = useConfirm();
  const click = async () => {
    const result = await confirm({
      message: (
        <span>
          従業員に見せながら操作してください。
          誤操作された場合、クーポンは無効になりますのでご注意ください
          <br />
          <br />
          <div style={{ textAlign: "center", fontSize: "1.2em" }}>
            クーポンを使用しますか？
          </div>
        </span>
      ),
    });
    if (!result) return;
    if (!couponDoc) return;
    updateDoc(couponDoc, { status: "used" });
  };
  return (
    <ConsumerLayout shopId={shopId} skipAuthentication>
      {loading && (
        <div style={{ padding: "20px" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {!loading && couponData && (
        <div className={"wide-form"}>
          <section className="hero">
            <div className="hero-body">
              <h1 className="title">特典クーポン</h1>
            </div>
          </section>
          <section className="main-section adjust-width">
            {couponData?.status === "valid" && (
              <div style={{ padding: "10px 0" }}>
                この画面を従業員に見せてください。
              </div>
            )}
            {couponData?.status !== "valid" && (
              <div style={{ padding: "10px 0" }}>
                このクーポンは使用済みです。
              </div>
            )}

            <div className="ticket" style={{ margin: "0 auto" }}>
              <div className="ticket-inner">
                <div className="ticket-left">
                  <div style={{ lineHeight: 1 }}>{couponId}</div>
                  <div className="ticket-code">{rewardDoc?.rewardName}</div>
                  {/* <div className="ticket-name">Jゴルフ鶴ヶ島</div> */}
                </div>
                <div className="ticket-right">
                  <div className="ticket-ppp">有効期限</div>
                  <div>{formatDate(couponData.expiredAt)}</div>
                  <div style={{}}>
                    <img
                      src={clientDoc?.logoUrl}
                      style={{
                        width: "130px",
                        height: "40px",
                        marginTop: "0.8em",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {couponData?.status === "valid" && (
              <>
                <div>
                  <div style={{ padding: "10px 0" }}>
                    <Button variant="primary" onClick={click}>
                      クーポンを使用済みにする
                    </Button>
                  </div>
                </div>
                <div style={{ whiteSpace: "pre-wrap", marginTop: "8px" }}>
                  {rewardDoc?.couponDescription}
                </div>
              </>
            )}
            {couponData?.status === "used" && (
              <>
                <div>
                  <div style={{ padding: "10px 0" }}>
                    <Button onClick={() => window.close()}>
                      この画面を閉じる
                    </Button>
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
      )}
    </ConsumerLayout>
  );
}
export default ConsumerCoupon;
