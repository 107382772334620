import { setDoc, doc, DocumentReference } from "firebase/firestore";
import { mailLogCollection } from "models/collection";
import { cleanObject } from "./firestore";
import { couponData } from "models/data";
import { clientDomain } from "./domain";
import { resolveRoute } from "pages";

export const createCoupon = async (
  couponDoc: DocumentReference<couponData>,
  value: couponData,
  shopId: string
) => {
  cleanObject(value);
  await setDoc(couponDoc, {
    ...value,
    shopId,
    createdAt: Date.now(),
    status: "valid",
  });
  const { id: couponId } = couponDoc;
  const mailDoc = doc(mailLogCollection);
  await setDoc(mailDoc, {
    createdAt: Date.now(),
    to: value.email,
    shopId,
    template: {
      name: "coupon",
      data: {
        couponUrl: couponUrl(shopId, couponId),
        couponId,
      },
    },
  });
};

export const couponUrl = (shopId: string | undefined, couponId: string) =>
  `${clientDomain}${decodeURIComponent(
    resolveRoute("CONSUMER_COUPON", { shopId: shopId || "*****", couponId })
  )}`;
