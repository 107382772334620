import {
  FieldPath,
  Query,
  OrderByDirection,
  where,
  WhereFilterOp,
  documentId,
  limit,
  limitToLast,
  orderBy,
  QueryConstraint,
} from "firebase/firestore";

export const operatorMap = {
  $gt: ">",
  $gte: ">=",
  $eq: "==",
  $lt: "<",
  $lte: "<=",
  $in: "in",
  $elemMatch: "array-contains",
  ">": ">",
  ">=": ">=",
  "==": "==",
  "<": "<",
  "<=": "<=",
  in: "in",
  "array-contains": "array-contains",
  "array-contains-any": "array-contains-any",
};

export type operatorMapType = typeof operatorMap;
export type operator = keyof operatorMapType;
export type filterFieldParams =
  | {
      [O in operator]?: O extends "in"
        ? string | number | string[] | number[]
        : string | number;
    }
  | string
  | number
  | boolean
  | undefined;
export type filterParams = { [K in string]: filterFieldParams };
export type deriveQueryParams = {
  filter?: filterParams;
  range?: filterFieldParams;
  orderBy?: [
    FieldPath: string | FieldPath,
    directionStr?: OrderByDirection | undefined
  ][];
  limit?: number;
  limitToLast?: number;
};

type entriesResult<T> = [keyof T, T[keyof T]][];

const deriveQueryForKey = <T>({
  condition,
  key,
}: {
  condition: filterFieldParams;
  key: FieldPath | string;
}) => {
  if (condition === undefined) {
    return [];
  } else if (typeof condition === "object") {
    const queries = [];
    for (const [operator, value] of Object.entries(condition) as entriesResult<
      typeof condition
    >) {
      const firestoreOperator = operatorMap[operator];
      if (!firestoreOperator) {
        console.error("You cannot use this query in firestore", condition);
        continue;
      }
      queries.push(where(key, firestoreOperator as WhereFilterOp, value));
    }
    return queries;
  } else {
    return [where(key, "==", condition)];
  }
};

export const getQueryConstraints = <T>(params: deriveQueryParams) => {
  const queries = [] as QueryConstraint[];
  if (params.filter) {
    for (const key of Object.keys(params.filter)) {
      queries.push(
        ...deriveQueryForKey({
          key,
          condition: params.filter[key],
        })
      );
    }
  }
  if (params.range) {
    queries.push(
      ...deriveQueryForKey({
        key: documentId(),
        condition: params.range,
      })
    );
  }
  if (params.orderBy) {
    queries.push(
      ...params.orderBy.map((orderByParam) => orderBy(...orderByParam))
    );
  }

  if (typeof params.limit === "number") {
    queries.push(limit(params.limit));
  }
  if (typeof params.limitToLast === "number") {
    queries.push(limitToLast(params.limitToLast));
  }
  return queries;
};
