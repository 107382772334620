import React, { useCallback, useMemo, useRef, useState } from "react";
import { setCustomTag, setCustomTagsBulk, useMediaList } from "models/media";
import { MEDIAS } from "../route";
import { Button, OverlayTrigger, Popover, Stack } from "react-bootstrap";
import { useHashTagList } from "models/hashTag";
import { SchemaInput } from "components/SchemaInput";
import { useCustomTagList } from "models/customTag";
import { mediaModel } from "models/data";
import { Schema } from "schemaComponents";
import { Value } from "react-hook-schema-form";
import { useDownload, useModalForm } from "hooks/dialogs";
import schema from "./schema";
import { arrayToCsv } from "components/SchemaListPage/csvUtil";
import dayjs from "dayjs";
import { parse } from "node:path/win32";
import { deleteField, FieldPath } from "firebase/firestore";
import { useAdminAuthentication } from "hooks/auth";

export const LIST_MEDIAS = MEDIAS.sub("", Medias);

function Medias({ campaignId }: { campaignId: string }) {
  const { list: hashTags } = useHashTagList({ campaignId });
  const { list: customTags } = useCustomTagList({ campaignId });
  const { list: rawItems } = useMediaList({ campaignId });
  const [condition, setCondition] = useState<{
    hashTags?: string[];
    customTags?: string[];
    editTarget: string;
  }>();
  const { role } = useAdminAuthentication();
  const isMaster = role === "master";

  const {
    likeCount,
    commentCount,
    postCount,
    items,
    categorizedItems,
    targetOption,
    targetTag,
  } = useMemo(() => {
    const targetTag = customTags?.find(
      (customTag) => customTag.id === condition?.editTarget
    );
    const targetOption: {
      title: string;
      value: number | boolean | undefined;
      prev?: number | boolean | undefined;
      next?: number | boolean | undefined;
    }[] = (() => {
      if (!targetTag) {
        return [{ title: "すべて", value: undefined }];
      }
      const options = [
        ...(targetTag.options || [{ title: "選択済み", value: true }]),
        { title: "未選択", value: undefined },
      ];
      return options.map((option, index) => {
        const prev = options[index - 1]?.value;
        const next = options[index + 1]?.value;
        return {
          ...option,
          ...(index > 0 ? { prev } : undefined),
          ...(index < options.length - 1 ? { next } : undefined),
        };
      });
    })();
    const items = rawItems?.filter((item) => {
      return (
        (!condition?.hashTags ||
          condition.hashTags.every((hashTag) => item.hashTag[hashTag])) &&
        (!condition?.customTags ||
          condition.customTags.every(
            (customTag) => item.customTag?.[customTag]
          )) &&
        Math.max(
          ...Object.values(item.hashTag).map((value) => {
            const [timeString] = (value as unknown as string)?.split?.("-") || [
              0,
            ];
            return parseInt(timeString);
          })
        ) >
          Date.now() - 2 * 24 * 3600 * 1000
      );
    });
    const categorizedItems: Record<string, (mediaModel & { id: string })[]> =
      {};
    const availableTagValue = new Set(
      targetOption?.map((option) => `${option.value}`)
    );
    if (targetTag) {
      const targetTagId = `${targetTag?.id}`;
      for (const item of items || []) {
        let tagValue = `${item.customTag?.[targetTagId]}`;
        if (!availableTagValue.has(tagValue)) {
          tagValue = "undefined";
        }
        const arr = (categorizedItems[tagValue] =
          categorizedItems[tagValue] || []);
        arr.push(item);
      }
    } else {
      categorizedItems[`${undefined}`] = items || [];
    }

    let likeCount = 0;
    let commentCount = 0;
    for (const item of items || []) {
      likeCount += item.like_count || 0;
      commentCount += item.comments_count || 0;
    }
    const postCount = items?.length;
    return {
      items,
      likeCount,
      commentCount,
      postCount,
      categorizedItems,
      targetOption,
      // targetOption: [...targetOption, { title: "未定義", value: -1 }],
      targetTag,
    };
  }, [rawItems, hashTags, customTags, condition]);

  const download = useDownload();
  const downloadHandler = useCallback(
    (e) => {
      e.preventDefault();
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const hashTagMap = new Map<string, string>(
        hashTags?.map((hashTag) => [
          hashTag.hashTagId as string,
          hashTag.hashTagName,
        ])
      );
      const customTagMap = new Map<string, string>(
        customTags?.map((customTag) => [
          customTag.id as string,
          customTag.customTagName,
        ])
      );
      download({
        data: [
          bom,
          arrayToCsv(
            items?.map((item) => {
              return {
                ...item,
                ...Object.fromEntries(
                  Object.entries(item.customTag || {}).map(([key, value]) => {
                    return [customTagMap.get(key), value];
                  })
                ),
                ...Object.fromEntries(
                  Object.entries(item.hashTag || {}).map(([key, value]) => {
                    return [hashTagMap.get(key), value];
                  })
                ),
                mediaUrl: `https://www.instagram.com/p/${item.id}/`,
                timestamp: dayjs(item.timestamp).valueOf(),
              };
            }) || [],
            {
              schemaType: "object",
              properties: [
                ...schema.properties,
                ...(hashTags?.map((hashTag) => ({
                  propertyName: hashTag.hashTagName,
                  title: hashTag.hashTagName,
                  schema: { schemaType: "boolean" as const },
                })) || []),
                ...(customTags?.map((customTag) => ({
                  propertyName: customTag.customTagName,
                  title: customTag.customTagName,
                  schema: { schemaType: "text" as const },
                })) || []),
              ],
            }
          ),
        ],
        mimeType: "text/csv",
      });
    },
    [items]
  );
  const modalForm = useModalForm();
  const uploadHandler = useCallback(async () => {
    const response = await modalForm<
      | {
          data?: ({ id: string } & Record<string, string>)[];
        }
      | undefined
    >({
      params: {
        title: "データアップロード",
        schema: {
          schemaType: "object",
          properties: [
            {
              title: "データ(TSV))",
              propertyName: "data",
              schema: {
                schemaType: "tsv",
              },
            },
          ],
        },
      },
    });
    if (!response?.data) {
      return;
    }
    const customTagMap = new Map<string, string>(
      customTags?.map((customTag) => [
        customTag.customTagName,
        customTag.id as string,
      ])
    );
    console.log({ customTagMap, customTags });
    const tasks = response.data
      .map((item) => {
        let customeTags: undefined | Record<string, number | true | FieldPath>;
        for (const [key, value] of Object.entries(item) as [string, string][]) {
          const customTagId = customTagMap.get(key);
          if (customTagId) {
            const _value = (() => {
              if (value === "TRUE") {
                return true;
              } else if (value === "FALSE") {
                return deleteField();
              } else if (value.match(/(?:^[1-9][[0-9]*|0)$/)) {
                return parseInt(value);
              }
            })();
            if (_value !== undefined) {
              customeTags = customeTags || {};
              customeTags[customTagId] = _value;
            }
          }
        }
        if (customeTags) {
          return {
            mediaId: item.id,
            customeTags,
          };
        }
        return undefined;
      })
      .filter((item) => item) as {
      mediaId: string;
      customeTags: Record<string, number | true | FieldPath>;
    }[];
    setCustomTagsBulk({ campaignId }, tasks);
    console.log(response, tasks);
  }, [customTags]);

  return (
    <>
      <section className="hero">
        <div className="hero-body">
          <Stack direction="horizontal" gap={3}>
            <h1 className="title">投稿一覧</h1>
            <div className="ms-auto">
              <Button onClick={downloadHandler} variant="outline-secondary">
                ダウンロード
              </Button>{" "}
              {isMaster && (
                <Button onClick={uploadHandler} variant="outline-secondary">
                  アップロード
                </Button>
              )}
            </div>
          </Stack>
        </div>
      </section>

      <section className="main-section wide-form">
        <SchemaInput
          schema={{
            schemaType: "object",
            properties: [
              {
                propertyName: "hashTags",
                title: "ハッシュタグ",
                noLabel: true,
                schema: {
                  schemaType: "multipleSelector",
                  options:
                    hashTags?.map((hashTag) => ({
                      title: `#${hashTag.hashTagName}`,
                      value: hashTag.hashTagId || "",
                    })) || [],
                },
              },
              {
                propertyName: "customTags",
                title: "絞り込み",
                noLabel: true,
                schema: {
                  schemaType: "multipleSelector",
                  options:
                    customTags
                      ?.filter((customTag) => customTag.enableFilter)
                      .map((customTag) => ({
                        title: customTag.customTagName,
                        value: customTag.id,
                      })) || [],
                },
              },
              {
                propertyName: "editTarget",
                title: "編集",
                schema: {
                  schemaType: "selector",
                  options:
                    customTags
                      ?.filter((customTag) => customTag.enableEdit)
                      .map((customTag) => ({
                        title: customTag.customTagName,
                        value: customTag.id,
                      })) || [],
                },
              },
            ],
          }}
          data={{}}
          onValueChange={(data) => setCondition(data)}
        />
      </section>

      {items && (
        <section className="main-section">
          <div className="row">
            <div className="col-sm-3">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-file-document-outline" />
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Number of Customers"
                  >
                    投稿
                  </h5>
                  <h3 className="mt-3 mb-3">{postCount}</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-thumb-up-outline" />
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Number of Customers"
                  >
                    いいね
                  </h5>
                  <h3 className="mt-3 mb-3">{likeCount}</h3>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card widget-flat">
                <div className="card-body">
                  <div className="float-end">
                    <i className="mdi mdi-comment-outline" />
                  </div>
                  <h5
                    className="text-muted fw-normal mt-0"
                    title="Number of Customers"
                  >
                    コメント
                  </h5>
                  <h3 className="mt-3 mb-3">{commentCount}</h3>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="main-section">
        {targetOption.map((option) => (
          <div key={`${option.value}`}>
            <div className="label" style={{ fontSize: "1.2em" }}>
              {option.title} ({categorizedItems[`${option.value}`]?.length || 0}
              ){/* {JSON.stringify(option)} */}
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "wrap",
              }}
            >
              {categorizedItems[`${option.value}`]?.map((item) => {
                return (
                  <div
                    key={item.id}
                    style={{ width: "150px" }}
                    className="instagram-list-item"
                  >
                    <a
                      target={item.id}
                      href={`https://www.instagram.com/p/${item.id}/`}
                    >
                      {item.media_type === "VIDEO" ? (
                        <video
                          src={item.media_url}
                          style={{
                            height: "150px",
                            width: "150px",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <img
                          src={`https://storage.googleapis.com/fancrew-campaign.appspot.com/image/300x300/${item.imageId}`}
                          style={{
                            height: "150px",
                            width: "150px",
                            objectFit: "contain",
                          }}
                        />
                      )}
                    </a>
                    <div>
                      <i className="mdi mdi-thumb-up-outline" />
                      {item.like_count}&nbsp;
                      <i className="mdi mdi-comment-outline" />
                      {item.comments_count}
                    </div>
                    <div>
                      {targetTag && "prev" in option && (
                        <i
                          className="mdi mdi-arrow-up"
                          style={{
                            color: "#00f",
                            cursor: "pointer",
                            fontSize: "1.2em",
                          }}
                          onClick={() =>
                            setCustomTag(
                              { campaignId },
                              item.id,
                              targetTag.id,
                              option.prev
                            )
                          }
                        />
                      )}
                      {targetTag && "next" in option && (
                        <i
                          className="mdi mdi-arrow-down"
                          style={{
                            color: "#00f",
                            cursor: "pointer",
                            fontSize: "1.2em",
                          }}
                          onClick={() =>
                            setCustomTag(
                              { campaignId },
                              item.id,
                              targetTag.id,
                              option.next
                            )
                          }
                        />
                      )}

                      {targetTag && (
                        <PopoverSelector
                          schema={{
                            schemaType: "selector",
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            options: targetOption as any,
                          }}
                          onValueChange={(value) => {
                            setCustomTag(
                              { campaignId },
                              item.id,
                              targetTag.id,
                              value || false
                            );
                          }}
                          data={item.customTag?.[targetTag.id]}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div></div>
          </div>
        ))}
      </section>
    </>
  );
}

export default Medias;

const PopoverSelector = ({
  schema,
  data,
  onValueChange,
}: {
  schema: Schema;
  data: Value;
  onValueChange: (value: Value) => void;
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <OverlayTrigger
        trigger={"click"}
        overlay={(props) => {
          return (
            <Popover {...props}>
              <Popover.Body>
                <SchemaInput
                  schema={schema}
                  data={data}
                  onValueChange={onValueChange}
                />
              </Popover.Body>
            </Popover>
          );
        }}
        rootClose={true}
      >
        <i
          className="mdi mdi-square-edit-outline"
          style={{
            color: "#00f",
            cursor: "pointer",
            fontSize: "1.2em",
          }}
          ref={target}
          onClick={() => setShow(!show)}
        />
      </OverlayTrigger>
    </>
  );
};
